import api from "app/api";

const sortRets = (a, b) => {
    return a.name < b.name ? -1 : a.name === b.name ? 0 : 1;
};

let defaultRetailers;

export async function getRetailers() {
    if (defaultRetailers) {
        return defaultRetailers;
    }

    const retailers = await api.retailer
        .list()
        .then((list) =>
            list.map((row) => {
                return {
                    ...row,
                    name: row.name.toUpperCase(),
                    label: row.name,
                    value: row.id,
                };
            })
        )
        .then((list) => list.sort(sortRets));
    defaultRetailers = retailers;
    return retailers;
}
