import Auth from "@aws-amplify/auth";
import { Credentials, Hub } from "@aws-amplify/core";
import {
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUserSession,
} from "amazon-cognito-identity-js";
import api from "app/api";
import settings from "app/settings";
import store from "app/store";
import normalizeUser from "common/src/api/normalize/user";
import appDataSource from "common/src/appDataSource";
import hub from "common/src/hub";
import AnalyticsTiming from "common/src/lib/analytics/Timing";
import getCookie from "common/src/lib/cookie/get";
import setCookie from "common/src/lib/cookie/set";
import singlePromise from "common/src/lib/js/singlePromise";
import getQueryParam from "common/src/lib/url/getQueryParam";
import { data as appData } from "common/src/store/app";
import { data as catalogueData } from "common/src/store/catalogue";
import { data, ui } from "common/src/store/user";
import user from "common/src/user";
import { batch } from "react-redux";

let currentCheckPromise;

const setUserCookie = (uid) => {
    setCookie("uid", uid);
};

export async function signOut() {
    const state = store.getState();
    hub.dispatch("app-auth", "before-signout", state.user.current);
    await hub.callHandlers("before-signout", state.user.current);
    await Auth.signOut();
    hub.dispatch("app-auth", "after-signout", state.user.current);
    await hub.callHandlers("after-signout", state.user.current);
}

export const getCurrentCheckPromise = function() {
    if (currentCheckPromise) {
        return currentCheckPromise;
    }
    const state = store.getState();
    if (state.user.current) {
        return Promise.resolve(state.user.current);
    }
    else return checkCurrent();
};

const loadNliGeo = () => {
    const qs = {};
    const country = getCookie(`tf-${process.env.REACT_APP_ENV}-country`);
    const currency = getCookie(`tf-${process.env.REACT_APP_ENV}-currency`);

    if (country) {
        qs.country = country;
    }
    if (currency) {
        qs.currency = currency;
    }

    api.unauth.get("/geo", { queryStringParameters: qs }).then((geo) => {
        store.dispatch(data.geo.set(geo));
        hub.dispatch("app-auth", "geo-loaded", geo, true);
    });
};

export const reloadCurrent = async () => {
    const qs = {};
    const country = getCookie(`tf-${process.env.REACT_APP_ENV}-country`);
    const currency = getCookie(`tf-${process.env.REACT_APP_ENV}-currency`);

    if (country) {
        qs.country = country;
    }
    if (currency) {
        qs.currency = currency;
    }

    let userData = await Auth.currentAuthenticatedUser({ bypassCache: true });
    let me = await api.backend.get("/me", { queryStringParameters: qs });
    let at = userData.signInUserSession.accessToken;
    const user = {
        groups: [],
    };

    user.signedInAt = new Date(at.payload.auth_time * 1000).toISOString();

    Object.assign(user, normalizeUser(me.user));

    if (at.payload && at.payload["cognito:groups"]) {
        user.groups = at.payload["cognito:groups"];
    }

    const attributes = userData.attributes
        || userData.signInUserSession.idToken.payload;

    user.emailVerified = attributes.email_verified;
    user.phoneVerified = attributes.phone_number_verified;
    user.currentProvider = "cognito";
    const origCurrency = getCookie(
        `tf-${process.env.REACT_APP_ENV}-currency-original`,
    );

    Object.assign(user, {
        geo: { ...me.geo, original: origCurrency === "1" },
        // commissionRates: me.commissionRates
    });

    store.dispatch(data.current.set(user));
};

export const checkCurrent = singlePromise(function(opt = {}) {
    const { shortInfo = false, skipLoading = false, bypassCache = true } = opt;
    store.dispatch(ui.checking(true));

    const refUserId = getQueryParam("_refid") || null;
    if (refUserId) {
        store.dispatch(appData.session.set({ referenceUserId: refUserId }));
    }

    return (currentCheckPromise = Auth.currentAuthenticatedUser({ bypassCache })
        // check if user session is still valid
        // if not, signout
        .then((user) => {
            if (!user.signInUserSession) {
                return AnalyticsTiming.get().trackAsync("user/get-session")(
                    () =>
                        Auth.currentSession()
                            .then((session) => {
                                return user;
                            })
                            .catch((err) => {
                                setUserCookie(null);
                                console.log(err);
                                return Promise.reject("NOT_AUTHENTICATED");
                            }),
                );
            }
            return user;
        })
        .then(async (user) => {
            let at = user.signInUserSession.accessToken,
                it = user.signInUserSession.idToken,
                groups = at.payload["cognito:groups"],
                tf = JSON.parse(it.payload.thefloorr),
                id = tf.id;

            if (settings.allowGroups && settings.allowGroups !== "*") {
                const allow = settings.allowGroups.filter(
                    (g) => groups.indexOf(g) !== -1,
                );
                if (!allow.length) {
                    setUserCookie(null);
                    Auth.signOut();
                    hub.dispatch("app-auth", "access-denied");
                    return Promise.reject();
                }
            }

            if (settings.disallowGroups) {
                const disallow = settings.disallowGroups.filter(
                    (g) => groups.indexOf(g) !== -1,
                );
                if (disallow.length > 0) {
                    setUserCookie(null);
                    Auth.signOut();
                    hub.dispatch("app-auth", "access-denied");
                    return Promise.reject();
                }
            }

            batch(() => {
                store.dispatch(data.groups.set(groups));
                store.dispatch(data.id(id));
                store.dispatch(data.loggedIn(true));
                store.dispatch(ui.checking(false));
                !skipLoading && store.dispatch(ui.loading(true));
            });

            hub.dispatch("app-auth", "stateChange", true);

            const qs = {};
            // const country = getCookie(`tf-${process.env.REACT_APP_ENV}-country`);
            const currency = await appDataSource.resolveFirst(
                `tf-${process.env.REACT_APP_ENV}-currency`,
            );

            // if (shortInfo) {
            // 	qs.short = true;
            // }
            // if (country) {
            // 	qs.country = country;
            // }

            if (currency) {
                qs.currency = currency.toUpperCase();
            }

            return Promise.all([
                user,
                api.backend.get("/me", {
                    queryStringParameters: { short: shortInfo },
                }),
                api.unauth.get("/geo", { queryStringParameters: qs }),
                api.backend.get("/catalogue/rates"),
            ]);
        })
        .then((responses) => {
            let [ userData, me, geo, { commissionRates } ] = responses,
                at = userData.signInUserSession.accessToken,
                user = {
                    groups: [],
                };

            user.signedInAt = new Date(
                at.payload.auth_time * 1000,
            ).toISOString();

            Object.assign(user, normalizeUser(me.user));

            if (at.payload && at.payload["cognito:groups"]) {
                user.groups = at.payload["cognito:groups"];
            }

            const attributes = userData.attributes
                || userData.signInUserSession.idToken.payload;

            user.emailVerified = attributes.email_verified;
            user.phoneVerified = attributes.phone_number_verified;

            if (me.user.cognitoIdentities) {
                user.currentProvider = me.user.cognitoIdentities.find(
                    (i) => i.cognitoId === userData.username,
                ).provider;
            }
            else {
                user.currentProvider = "cognito";
            }

            setUserCookie(user.id);
            const origCurrency = getCookie(
                `tf-${process.env.REACT_APP_ENV}-currency-original`,
            );

            return {
                user,
                geo: { ...geo, original: origCurrency === "1" },
                commissionRates,
            };
        })
        .then(async ({ user, geo, commissionRates }) => {
            batch(() => {
                store.dispatch(data.current.set(user));
                store.dispatch(data.geo.set(geo));
                !skipLoading && store.dispatch(ui.loading(false));
                // store.dispatch(catalogueData.commissionRates.set(commissionRates));
            });
            if (
                user.groups.indexOf("Admin") === -1
                && user.groups.indexOf("GPS") === -1
                && user.groups.indexOf("FRI") === -1
            ) {
                window.restoreGa && window.restoreGa();
            }
            // expose api for admins
            if (
                user.groups.indexOf("Admin") === -1
                && typeof window !== "undefined"
            ) {
                window.tfApi = api;
            }

            await hub.callHandlers("user-info-ready");

            hub.dispatch("app-auth", "info-loaded", user);
            hub.dispatch("app-auth", "geo-loaded", geo, true);
            return user;
        })
        .finally((user) => {
            currentCheckPromise = null;
            return user;
        })
        .catch(async (err) => {
            console.log("auth error", err);
            await loadNliGeo();

            batch(() => {
                store.dispatch(data.loggedIn(false));
                !skipLoading && store.dispatch(ui.loading(false));
                store.dispatch(ui.checking(false));
            });

            window.restoreGa && window.restoreGa();
            hub.dispatch("app-auth", "stateChange", false);

            setUserCookie(null);

            // check if there is still cached user
            // call without bypassCache
            return Auth.currentAuthenticatedUser()
                .then((user) => {
                    if (user) {
                        if (err === "not authenticated") {
                            setUserCookie(null);
                            signOut();
                        }
                    }
                })
                .catch((err) => {
                    // do nothing
                });
        }));
});

export function removeAvatar() {
    store.dispatch(ui.removingAvatar(true));

    return api.user
        .update(user.id(), { avatar: null })
        .then(() => {
            store.dispatch(data.current.update({ avatar: null }));
            hub.dispatch("user", "avatar-removed");
        })
        .finally(() => {
            store.dispatch(ui.removingAvatar(false));
        })
        .catch((err) => {
            hub.dispatch("error", "user-remove-avatar", err);
        });
}

export function update(attributes) {
    store.dispatch(ui.saving(true));

    let cgAttrs = {};
    // let currEmail = user.current().email;
    // let newEmail = attributes.email || currEmail;

    if (attributes.handle === "") {
        attributes.handle = null;
    }

    [
        [ "phone", "phone_number" ],
        [ "email", "email" ],
        [ "givenName", "given_name" ],
        [ "familyName", "family_name" ],
    ].forEach((pair) => {
        let [ dbName, cgName ] = pair;
        if (dbName in attributes) {
            cgAttrs[cgName] = attributes[dbName];
        }
    });

    return Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => Auth.updateUserAttributes(user, cgAttrs))
        .then((res) =>
            res === "SUCCESS"
                ? api.user.update(user.id(), attributes)
                : Promise.reject(res)
        )
        .then(() => Auth.currentAuthenticatedUser({ bypassCache: true }))
        .then((user) => {
            let { phone_number_verified, email_verified } = user.attributes;
            store.dispatch(
                data.current.update({
                    emailVerified: email_verified, // newEmail === currEmail && email_verified ? true : false,
                    phoneVerified: phone_number_verified,
                    ...attributes,
                }),
            );
            hub.dispatch("user", "updated");
            return true;
        })
        .finally((res) => {
            store.dispatch(ui.saving(false));
            return res;
        })
        .catch((err) => {
            hub.dispatch("error", "user-update", err);
            return err;
        });
}

export async function acceptInvitation(data) {
    // if (!data.invitation.accepted) {
    await api.backend.post("/me/accept-invitation", {
        body: {
            id: data.invitationId,
        },
    });
    // }
}

export function resendEmailVerification(from = null) {
    from = from
        || (window && window.location ? window.location.toString() : null);

    return api.backend
        .post("/me/resend-email-verification", {
            body: { from },
        })
        .catch((err) => {
            hub.dispatch("error", "resend-email-verification", err);
        });
}

export async function checkWhatsappExists(whatsapp, userId) {
    const res = await api.backend.post("/me/check-whatsapp-exists", {
        body: { whatsapp },
    });
    return res.found;
}

export function verifyEmail(reqId, code) {
    return (
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return user
                    ? api.backend.post("/me/verify-email", {
                        body: { code },
                    })
                    : api.unauth.post("/verify-email", {
                        body: { id: reqId, code },
                    });
            })
            // why does it repeat unauth request?
            .catch((err) => {
                return api.unauth.post("/verify-email", {
                    body: { id: reqId, code },
                });
            })
            .then((resp) => {
                if (resp.success && user.loggedIn()) {
                    store.dispatch(
                        data.current.update({
                            emailVerified: true,
                        }),
                    );
                    Auth.currentAuthenticatedUser({ bypassCache: true });
                    hub.dispatch("user-analytics", "change", {
                        emailVerified: true,
                    });
                    hub.dispatch("posthog", "event", {
                        event: "email_verified",
                    });
                }
                else if (!resp.success) {
                    let message = "";
                    if (resp.status === "INVALID") {
                        message = "Code is invalid";
                    }
                    else if (resp.status === "EXPIRED") {
                        message = "Code is expired";
                    }
                    else if (resp.status === "USED") {
                        message = "Code is already used";
                    }
                    else if (resp.status === "VERIFIED") {
                        message = "Already verified";
                    }
                    resp.errorMessage = message;
                    hub.dispatch("error", "verify-email", message);
                }

                return resp;
            })
            .catch((err) => {
                hub.dispatch("error", "verify-email", err);
            })
    );
}

export function resendPhoneVerification() {
    return Auth.currentAuthenticatedUser().then((user) => {
        return new Promise(function(resolve, reject) {
            user.getAttributeVerificationCode("phone_number", {
                onFailure: reject,
                onSuccess: resolve,
            });
        });
    });
}

export function verifyPhone(code) {
    return Auth.currentAuthenticatedUser()
        .then((user) => {
            return new Promise(function(resolve, reject) {
                user.verifyAttribute("phone_number", code, {
                    onFailure: reject,
                    onSuccess: resolve,
                });
            });
        })
        .then(() => {
            store.dispatch(
                data.current.update({
                    phoneVerified: true,
                }),
            );
            Auth.currentAuthenticatedUser({ bypassCache: true });
            hub.dispatch("user-analytics", "change", { phoneVerified: true });
            hub.dispatch("posthog", "event", { event: "phone_verified" });
        });
}

export async function agreeToFriTerms() {
    await api.user.update(user.id(), { friTerms: 1 });
    store.dispatch(
        data.current.update({
            friTerms: 1,
        }),
    );
}

/*export async function setFRIGroup() {
	await api.user.update(user.id(), { groups: "User,FRI" });
	store.dispatch(data.current.update({
		groups: "User,FRI"
	}));
}*/

export function checkHandle(handle) {
    const where = {
        handle: { _eq: handle },
        id: { _neq: user.id() },
    };

    return api.user.list({ where, limit: 1 }).then((users) => users.length > 0);
}

export function checkEmail(email) {
    return api.backend
        .post("/me/check-email", { body: { email } })
        .then((resp) => resp.exists);
}

let dataSubscription;

const userDataGraph = `
    hasSavedLooks: savedLooks(
                    limit:1, 
                    where: { 
                        look: { 
                            published: { _eq: true }}}) 
                    { lookId }
    hasSavedProducts: savedProducts(
                    limit:1
                    where: {
                        product: {
                            look: {
                                published: { _eq: true }}}}) 
                    { productId }
    hasSavedCatalogueProducts: savedCatalogueProducts(limit:1)
                    { productCatalogueId }
    consultationsAsCustomer_aggregate {
        aggregate {
            count
        }
    }
    contacts {
        id
        type
        value
        normalized
        notifications
        verified
    }
    sizes {
        id
        type
        system
        value
        comment
    }
    paymentSettings {
        friCommission
        contributorCommission
		pseCommission
    }
`;

export const subscribeToUserData = async (userId) => {
    if (dataSubscription) {
        return;
    }

    const where = {
        id: { _eq: userId },
    };
    dataSubscription = await api.user.subscribeList(
        { where },
        userDataGraph,
        (userData) => {
            const state = store.getState();
            if (state.user.current) {
                userData = normalizeUser(userData[0]);
                if (userData) {
                    delete userData.details;
                    store.dispatch(data.current.update(userData));
                }
            }
        },
    );
};

export const unsubscribeFromUserData = () => {
    if (dataSubscription) {
        if (dataSubscription.subscription) {
            dataSubscription.subscription.unsubscribe();
        }
        dataSubscription = null;
    }
};

const AMPLIFY_SYMBOL =
    typeof Symbol !== "undefined" && typeof Symbol.for === "function"
        ? Symbol.for("amplify_default")
        : "@@amplify_default";

async function insertCognitoSession(authResult) {
    const idToken = new CognitoIdToken({ IdToken: authResult.IdToken });
    const accessToken = new CognitoAccessToken({
        AccessToken: authResult.AccessToken,
    });
    const refreshToken = new CognitoRefreshToken({
        RefreshToken: authResult.RefreshToken,
    });

    const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
    };

    const userSession = new CognitoUserSession(sessionData);
    Credentials.set(userSession, "session");

    const cognitoUser = Auth.createCognitoUser(
        userSession.getIdToken().decodePayload()["cognito:username"],
    );
    cognitoUser.setSignInUserSession(userSession);
    Hub.dispatch(
        "auth",
        {
            event: "signIn",
            data: cognitoUser,
            message: "Impersonator authenticated",
        },
        "Auth",
        AMPLIFY_SYMBOL,
    );
}

export async function impersonate(adminEmail, adminPassword, targetUserEmail) {
    const resp = await api.unauth.post("/login-as", {
        body: {
            adminEmail,
            adminPassword,
            targetUserEmail,
        },
    });

    const { AuthenticationResult } = resp;

    if (AuthenticationResult) {
        try {
            await Auth.signOut();
            await insertCognitoSession(AuthenticationResult);
            await checkCurrent();
        }
        catch (err) {
            console.log(err);
        }
    }
}

window.userSignOut = signOut;
