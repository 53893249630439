export default function (moodboard) {
    if (!moodboard.__normalized) {
        if (moodboard.hideMoodboards && moodboard.hideMoodboards.length > 0) {
            moodboard.deleted = true;
        }

        if (moodboard.productReferences_aggregate) {
            moodboard.productsCount =
                moodboard.productReferences_aggregate.aggregate.count;
            delete moodboard.productReferences_aggregate;
        } else {
            moodboard.productsCount = 0;
        }

        if (moodboard.productReferences) {
            moodboard.images = moodboard.productReferences
                .map((p) => {
                    const images = p.catalogueProductReference?.images;
                    return images?.[0];
                })
                .filter((i) => !!i);
        } else {
            moodboard.images = [];
        }

        if (moodboard.image) {
            moodboard.image = JSON.parse(moodboard.image);
        }
    }

    moodboard.__normalized = true;
    return moodboard;
}
