import Observable from "@kuindji/observable";
import useDictRef from "common/src/hooks/useDictRef";
import { useCallback, useEffect, useState } from "react";

const defaultDeps = [ false ];
const cache = {};
const cacheObservable = new Observable();

export function getQueryCache(
    key,
    prop,
    defaultValue,
) {
    if (!key) {
        return defaultValue;
    }
    const item = cache[key];
    if (item) {
        if (item.expireAt > new Date().getTime()) {
            return item[prop];
        }
        delete cache[key];
    }
    return defaultValue;
}

function useQuery(fn, deps = defaultDeps, options = {}) {
    const {
        append,
        prepend,
        params,
        rowIdKey,
        map,
        prepareParams,
        processResponse,
        prepare,
        onLoad,
        enabled = true,
        cacheKey,
        cacheTime = 1000 * 60 * 60,
    } = options;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [ data, setData ] = useState(() =>
        getQueryCache(
            cacheKey,
            "data",
            options.initialData !== undefined ? options.initialData : [],
        )
    );
    const [ error, setError ] = useState(null);
    const [ count, setCount ] = useState(
        options.initialData ? options.initialData.length : 0,
    );
    const [ extraData, setExtraData ] = useState(() =>
        getQueryCache(cacheKey, "extraData", {})
    );
    const ref = useDictRef({
        fn,
        append,
        prepend,
        params,
        prepareParams,
        processResponse,
        rowIdKey,
        map,
        prepare,
        onLoad,
        enabled,
        cacheKey,
        isLoading,
        data,
    });

    const prepareData = useCallback(
        (prev, data, overwrite) => {
            if (overwrite) {
                return data;
            }
            if (ref.prepare) {
                data = ref.prepare(data);
            }
            if (ref.map) {
                data = data.map(ref.map);
            }
            if (ref.append) {
                data = [ ...(prev || []), ...data ];
                // .filter(
                //     (obj, index, self) =>
                //         index === self.findIndex((o) => o.id === obj.id)
                // );
            }
            else if (ref.prepend) {
                data = [ ...(data || []), ...(prev || []) ];
                // .filter(
                //     (obj, index, self) =>
                //         index === self.findIndex((o) => o.id === obj.id)
                // );
            }
            if (ref.rowIdKey && prev && prev.length > 0) {
                data = data
                    .reverse()
                    .filter((r, inx, self) =>
                        typeof ref.rowIdKey === "string"
                            ? self.findIndex(
                                (r1) => r1[ref.rowIdKey] === r[ref.rowIdKey],
                            ) === inx
                            : self.findIndex(
                                (r1) => ref.rowIdKey(r1) === ref.rowIdKey(r),
                            ) === inx
                    )
                    .reverse();
            }
            ref.onLoad && ref.onLoad(data);
            return data;
        },
        [ ref ],
    );

    const refetch = useCallback(
        async (params, skipCache = false) => {
            if (!ref.enabled) {
                return;
            }

            const cacheKey = ref.cacheKey;

            if (ref.isLoading && ref.loadingCacheKey === cacheKey) {
                return;
            }

            if (cacheKey && !ref.append && !skipCache) {
                const cachedData = getQueryCache(cacheKey, "data");
                const cachedExtraData = getQueryCache(
                    cacheKey,
                    "extraData",
                );
                if (cachedData !== undefined) {
                    if (cachedData !== ref.data) {
                        setTimeout(() => {
                            setData(cachedData);
                            setExtraData(cachedExtraData);
                            ref.loadingCacheKey = undefined;
                            setIsLoading(false);
                        });
                    }
                    return;
                }
            }

            ref.isLoading = true;
            ref.loadingCacheKey = cacheKey;
            setIsLoading(true);

            let finalParams = { ...ref.params, ...params };
            if (ref.prepareParams) {
                finalParams = await ref.prepareParams(finalParams);
            }
            if (finalParams === false) {
                setData([]);
                setIsLoading(false);
                setExtraData({});
                setError(null);
                setCount(0);
            }
            else {
                setIsLoaded(false);
                const response = await ref.fn(finalParams);

                if (ref.loadingCacheKey !== cacheKey) {
                    return;
                }

                const { data, error, count, ...rest } = ref.processResponse
                    ? ref.processResponse(response)
                    : response;
                const preparedData = prepareData(
                    ref.data,
                    data,
                    finalParams.overwrite || false,
                );
                setData(preparedData);
                setIsLoading(false);
                setIsLoaded(true);
                setExtraData(rest || {});
                setError(error);
                if (count !== undefined) {
                    setCount(count);
                }

                ref.loadingCacheKey = undefined;

                if (cacheKey) {
                    cache[cacheKey] = {
                        data: preparedData,
                        extraData: rest || {},
                        params: finalParams,
                        expireAt: new Date().getTime() + cacheTime,
                    };
                    cacheObservable.trigger(cacheKey);
                }
            }
        },
        [ ref, prepareData ],
    );

    const reset = useCallback(
        () => {
            setData([]);
            setCount(0);
            setError(null);
        },
        [],
    );

    useEffect(
        () => {
            refetch();
        },
        // eslint-disable-next-line
        deps,
    );

    return {
        data,
        isLoading,
        error,
        refetch,
        count,
        reset,
        extraData,
        isLoaded,
        setData,
    };
}

export default useQuery;
