import api from "app/api";
import store from "app/store";
import user from "common/src/user";

export async function getConsultationProductReferences({
    consultationId,
    offset = 0,
    limit,
}) {
    const graph = `
        consultationId
        products {
            catalogueProductReference {
                productId
                region
                images
            }
        }
    `;
    const where = {
        consultationId: Array.isArray(consultationId)
            ? { _in: consultationId }
            : { _eq: consultationId },
    };
    const input = { where };
    if (limit) {
        input.offset = offset;
        input.limit = limit;
    }
    const references = await api.look.list(input, graph);
    const productRegion = {};
    const savedImages = {};
    const productToConsultationMap = {};
    const productId = [];

    if (references && references.length > 0) {
        references.forEach((l) => {
            l.products?.forEach((p) => {
                const r = p.catalogueProductReference;
                if (r) {
                    productId.push(r.productId);
                    productToConsultationMap[r.productId] = l.consultationId;
                    productRegion[r.productId] = r.region;
                    if (r.images && r.images.length > 0) {
                        savedImages[r.productId] = r.images;
                    }
                }
            });
        });
    }

    return { productId, productRegion, savedImages, productToConsultationMap };
}

export async function getMoodboardProductReferences({
    moodboardId,
    offset = 0,
    limit,
}) {
    const graph = `
        id
        moodboardId
        position
        catalogueProductReference {
            productId
            region
            images
            metadata
        }
    `;
    const where = {
        moodboardId: Array.isArray(moodboardId)
            ? { _in: moodboardId }
            : { _eq: moodboardId },
    };
    const order = { position: "desc" };
    const input = { where, order };
    if (limit) {
        input.offset = offset;
        input.limit = limit;
    }
    const references = await api.moodboardProductReference.list(input, graph);
    const productRegion = {};
    const savedImages = {};
    const productToMoodboardMap = {};
    const metadata = {};
    const productId = references.map(
        (r) => r.catalogueProductReference.productId,
    );
    references.forEach((r) => {
        const p = r.catalogueProductReference;
        if (!p) {
            return;
        }
        productToMoodboardMap[p.productId] = r.moodboardId;
        productRegion[p.productId] = p.region;
        if (p.images && p.images.length > 0) {
            savedImages[p.productId] = p.images;
        }
        if (p.metadata) {
            metadata[p.productId] = p.metadata;
        }
    });

    return {
        productId,
        productRegion,
        savedImages,
        productToMoodboardMap,
        metadata,
    };
}

export async function referencedProductsLoader(options = {}) {
    const {
        moodboardId,
        offset,
        limit,
        query,
        // withCount = false,
        withReactions = false,
        reactionsUserId = user.id(),
        returnIds = false,
        referenceImages = null,
        useOriginalImages = false,
    } = options;

    const storeState = store.getState();
    const displayCurrency = storeState.user.geo.original
        ? null
        : storeState.user.geo.currency;

    let productIds = options.productIds || [];
    let productRegions = options.productRegions || {};
    let count = productIds.length;
    let products = [];
    let fetchedIds = [];
    let savedImages = referenceImages || {};
    let metadata = {};

    if (moodboardId) {
        const response = await getMoodboardProductReferences({
            moodboardId,
            offset,
            limit: query ? null : limit,
        });
        productIds = response.productId;
        savedImages = response.savedImages;
        productRegions = response.productRegion;
        metadata = response.metadata;
    }

    if (productIds.length > 0) {
        const body = query
            ? {
                id: productIds,
                page_size: productIds.length,
                product_region: productRegions,
                convert_to_currency: displayCurrency,
                with_retailer_commission: true,
                query,
                no_data: returnIds,
                with_used: true,
            }
            : {
                reference_id: productIds,
                product_region: productRegions,
                page_size: productIds.length,
                convert_to_currency: displayCurrency,
                with_retailer_commission: true,
                no_data: returnIds,
                with_used: true,
            };

        const { id, product } = await api.catalogue.post("/search", {
            body,
        });
        fetchedIds = id;
        products = product;
    }

    if (productIds.length === 0) {
        return { data: [], metadata: {}, count, hasMore: false };
    }

    if (withReactions && reactionsUserId) {
        const where = {
            catalogueProductReference: { productId: { _in: productIds } },
            userId: { _eq: reactionsUserId },
        };
        const reactions = await api.userReaction.list({ where });
        reactions.forEach((r) => {
            const p = products.find(
                (p) => p.id === r.catalogueProductReference.productId,
            );
            if (p) {
                p.reaction = r.reaction;
            }
        });
    }

    if (!useOriginalImages) {
        products.forEach((p) => {
            if (savedImages[p.id]) {
                p.images = savedImages[p.id];
            }
        });
    }

    const data = returnIds
        ? fetchedIds
        : productIds
            .map((pid) => products.find((p) => p.id === pid))
            .filter((p) => !!p);

    return { data, count, metadata, hasMore: data.length >= limit };
}
