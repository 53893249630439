import { defaultRates } from "common/src/lambdalib/userPaymentSettings";

export default function (user) {
    if (!user) {
        return null;
    }
    if (user.username && !user.id) {
        user.id = user.username;
    }
    if (user.hasSavedLooks) {
        user.hasSavedLooks = user.hasSavedLooks.length > 0;
    }
    // if (user.hasSavedProducts) {
    //     user.hasSavedProducts = user.hasSavedProducts.length > 0;
    // }
    // if (user.hasSavedCatalogueProducts) {
    //     user.hasSavedCatalogueProducts = user.hasSavedCatalogueProducts.length > 0;
    // }
    if (user.consultationsAsCustomer_aggregate) {
        user.receivingConsultations =
            user.consultationsAsCustomer_aggregate.aggregate.count;
    } else {
        user.receivingConsultations = 0;
    }

    if (user.details && typeof user.details === "string") {
        user.details = JSON.parse(user.details);
    } else if (!user.details) {
        user.details = { access: {} };
    }

    if (!user.details.access) {
        user.details.access = {};
    }

    // if (user.paymentSettings) {
    //     user.paymentSettings.friCommission = user.paymentSettings.friCommission || defaultRates.fri;
    //     user.paymentSettings.contributorCommission = user.paymentSettings.contributorCommission ||
    //                                                     defaultRates.contributor;
    //     user.paymentSettings.pseCommission = user.paymentSettings.pseCommission ||
    //                                                     defaultRates.pse;
    // }

    return user;
}
