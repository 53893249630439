import user from "common/src/user";
// import extractUrlFromClick from "common/src/lambdalib/extractUrlFromClick";
import getClickDomain from "common/src/lib/url/clickDomain";

const normalizeCatalogueProduct = (cp) => {
    if (!cp) {
        return null;
    }

    if (cp.__normalized) {
        return cp;
    }

    const p = { ...cp };
    const clickDomain = getClickDomain();
    p.trackable_url =
        `https://${clickDomain}/?` +
        `cpid=${p.id}&` +
        (user.loggedIn() ? `refid=${user.id()}&` : "") +
        `url=${p.click_url}`;
    p.__normalized = true;
    return p;

    // const clickUrl = cp.details
    //     ? cp.details.product_url || cp.details.url || cp.details.click_url
    //     : cp.url;

    // let images;
    // if (cp.images) {
    //     if (typeof cp.images === "string") {
    //         images = JSON.parse(cp.images);
    //     } else images = cp.images;
    // }

    // const p = {
    //     id: cp.product_web_id,
    //     product_web_id: cp.product_web_id,
    //     designers: cp.designers || [{ name: cp.brand }],
    //     images: images
    //         ? images.map((src) =>
    //               typeof src === "string" ? { src } : { ...src }
    //           )
    //         : [{ src: cp.image }],
    //     price: cp.price,
    //     salePrice: cp.salePrice || cp.sale_price || 0,
    //     currency: cp.currency,
    //     availability: cp.availability,
    //     retailer: cp.retailer,
    //     name: cp.name,
    //     description: cp.description,
    //     commissionExclusion: cp.commission_exclusion || false,
    //     sizes:
    //         cp.details && cp.details.sizes
    //             ? cp.details.sizes.filter((s) => s.size !== "-")
    //             : [],
    //     url: clickUrl,
    //     product_url:
    //         (cp.details ? cp.details.product_url : null) ||
    //         extractUrlFromClick(clickUrl),
    //     region: cp.region,
    //     __normalized: true,
    // };

    // if (cp.cdnImages) {
    //     p.cdnImages = cp.cdnImages.map((src) =>
    //         typeof src === "string" ? { src } : { ...src }
    //     );
    // }

    // if (!p.url || !p.url.indexOf) {
    //     //console.log(cp)
    // }

    // p.trackableUrl =
    //     `https://${clickDomain}/?` +
    //     `cpid=${p.id}&` +
    //     (user.loggedIn() ? `refid=${user.id()}&` : "") +
    //     `url=${extractUrlFromClick(p.url)}`;

    // if (cp.saved !== undefined) {
    //     p.saved = cp.saved;
    // }

    // return p;
};

export default normalizeCatalogueProduct;
