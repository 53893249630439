import {
    getOrderItemStatus,
    getOrderItemStatusName,
    getOrderStatus,
    STATUS_APPROVED,
    STATUS_APPROVED_AUTO,
    STATUS_APPROVED_MANUAL,
} from "app/lib/orderStatus";
import df from "common/src/lib/date/formats";
import formatCurrency from "common/src/lib/format/currency";
import moment from "moment";
import React from "react";

export const getProductRows = (order) => {
    return order.rakutenItems.map(item => {
        const details = JSON.parse(item.details);
        const rejected = item.manualStatus === "rejected";

        return {
            order,
            id: item.id,
            sku: item.sku,
            name: item.product,
            brand: details?.Product_Brand,
            quantity: item.itemsCount,
            details: JSON.parse(item.details),
            saleAmount: rejected ? 0 : item.saleAmount,
            commissionAmount: rejected ? 0 : item.commissionAmount,
            currency: item.currency,
            status: null,
            manualStatus: item.manualStatus,
            tf_status: getOrderItemStatus(order, item),
        };
    });
};

export const getProducts = async (order, renderItemDetails) => {
    const cols = [
        {
            id: "processDate",
            name: "Process date",
            render: c => moment(c.processDate).format(df.full),
        },
        { id: "product", name: "Product" },
        { id: "sku", name: "SKU" },
        {
            id: "brand",
            name: "Brand",
            render: (i => JSON.parse(i.details)?.Product_Brand),
        },
        {
            id: "sale",
            name: "Sale",
            render: i => formatCurrency(i.saleAmount, order.currency, 2),
        },
        {
            id: "commission",
            name: "TF Commission",
            render: i => formatCurrency(i.commissionAmount, order.currency, 2),
        },
        {
            id: "status",
            name: "TF Order status",
            render: i => getOrderItemStatusName(order, i),
        },
    ];
    const rows = order.rakutenItems.map(item => {
        const row = { ...item };
        const rejected = item.manualStatus === "rejected";
        if (rejected) {
            row.saleAmount = 0;
            row.commissionAmount = 0;
        }
        return row;
    });

    return {
        cols,
        rows,
        expandable: true,
        expandKey: "id",
        renderDetails: item => renderItemDetails(order, item),
    };
};

export const renderCommissionDetails = (comm) => {
    const json = {
        importedAt: comm.importDate,
    };

    if (comm.click && comm.click.user) {
        json.user = comm.click.user;
    }

    const code = JSON.stringify(json, null, 2);
    return (
        <pre>
            { code }
        </pre>
    );
};

export const getStatusClass = (comm) => {
    const s = getOrderStatus(comm);
    if (
        s === STATUS_APPROVED
        || s === STATUS_APPROVED_AUTO
        || s === STATUS_APPROVED_MANUAL
    ) {
        return "ok";
    }
    if (comm.autoApprovedAt !== null) {
        return "ok";
    }
    return comm.saleAmount > 0 ? "ok" : "notok";
};

export const hasHistory = (order) => {
    return false;
};

export const hasCorrections = (order) => {
    return false;
};
