import { Button, TextField } from "@mui/material";
import api from "common/src/api";
import Dialog from "common/src/components/dialog/Dialog";
import Table from "common/src/components/table/Table";
import Avatar from "common/src/components/user/Avatar";
import useQuery from "common/src/refactor/hooks/useQuery";
import { ui } from "common/src/store/dialogs";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import Pagination from "common/src/components/Pagination";
const PER_PAGE = 10;

const cols = [
    {
        id: "email",
        name: "Email",
        className: "grid-nowrap",
    },
    {
        id: "name",
        name: "Name",
        className: "grid-nowrap",
        render: u => (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                }}>
                <Avatar user={u} />
                {u.givenName} {u.familyName}
            </div>
        ),
        renderCsv: u => `${u.givenName} ${u.familyName}`,
    },
];

async function loadUsers({ offset = 0, limit = PER_PAGE, query = "" }) {
    const where = {};

    if (query) {
        where._or = [
            { email: { _ilike: `%${query}%` } },
            { givenName: { _ilike: `%${query}%` } },
            { familyName: { _ilike: `%${query}%` } },
        ];
    }

    const { items, count } = await api.user.list(
        {
            where,
            order: { createdAt: "desc" },
            offset,
            limit,
        },
        "id email givenName familyName",
        true,
    );

    return { data: items, count };
}

export default function UserSelectorDialog({ onSelect }) {
    const dispatch = useDispatch();
    const [ query, setQuery ] = useState("");
    const [ appliedQuery, setAppliedQuery ] = useState("");
    const [ page, setPage ] = useState(0);
    const [ selectedUser, setSelectedUser ] = useState(null);
    const { data, count } = useQuery(
        loadUsers,
        [ appliedQuery, page ],
        {
            initialData: [],
            params: {
                query: appliedQuery,
                offset: page * PER_PAGE,
                limit: PER_PAGE,
            },
        },
    );

    const onShowHide = useCallback(
        (state) => {
            if (state) {
            }
        },
        [],
    );

    const onQueryChange = useCallback(
        (e) => {
            setQuery(e.target.value);
        },
        [],
    );

    const onRowClick = useCallback(
        (user) => {
            setSelectedUser(user);
        },
        [],
    );

    const onSearch = useCallback(
        () => {
            setAppliedQuery(query);
            setPage(0);
        },
        [ query ],
    );

    const onCancel = useCallback(
        () => {
            setQuery("");
            setAppliedQuery("");
            setSelectedUser(null);
            dispatch(ui.hide("user-selector"));
        },
        [ dispatch ],
    );

    const getRowClass = useCallback(
        (user) => {
            return user.id === selectedUser?.id ? "selected" : "";
        },
        [ selectedUser ],
    );

    const onSelectClick = useCallback(
        () => {
            onSelect?.(selectedUser);
            dispatch(ui.hide("user-selector"));
        },
        [ onSelect, selectedUser, dispatch ],
    );

    return (
        <Dialog
            name="user-selector"
            className="user-selector-dialog"
            triggerMode="click"
            closeOnBody={true}
            closeOnOverlay={true}
            overlay={true}
            onChange={onShowHide}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                }}>
                <h4>User Selector</h4>
                <div
                    className="toolbar"
                    style={{ display: "flex", gap: "0.5rem", padding: 0 }}>
                    <TextField
                        style={{ width: "100%" }}
                        autoComplete="off"
                        variant="outlined"
                        value={query}
                        onChange={onQueryChange}
                        placeholder="Search for a user" />
                    <Button
                        variant="contained"
                        onClick={onSearch}>
                        Search
                    </Button>
                </div>
                <Table
                    variant="slim"
                    cols={cols}
                    rows={data}
                    onRowClick={onRowClick}
                    getRowClass={getRowClass} />
                <Pagination
                    page={page}
                    count={count}
                    perPage={PER_PAGE}
                    onChange={setPage} />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "1rem",
                    }}>
                    <Button variant="text" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!selectedUser}
                        onClick={onSelectClick}>
                        Select
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}
