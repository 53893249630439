import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import Dialog from "common/src/components/dialog/Dialog";
import Loader from "common/src/components/Loader";

import api from "app/api";
import store from "app/store";
import NullForm from "common/src/components/NullForm";
import useDictRef from "common/src/hooks/useDictRef";
import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import formatCurrency from "common/src/lib/format/currency";
import useQuery from "common/src/refactor/hooks/useQuery";
import { ui as ddUi } from "common/src/store/dialogs";

const DIALOG_NAME = "create-revolut-draft";

async function loadAccounts() {
    const data = await api.revolut.get("/accounts");
    const accounts = data.accounts
        .filter(a => {
            return a.balance > 0 && a.state === "active";
        })
        .map(a => {
            a.label = `${a.name} (${formatCurrency(a.balance, a.currency)})`;
            return a;
        });
    return { data: accounts };
}

async function loadCounterpartyCurrency({ userId }) {
    const resp = await api.revolut.get("/counterparty/currency", {
        queryStringParameters: {
            userId,
        },
    });
    return { data: resp?.currency || null };
}

async function loadPaymentAmount({ to, amount, from }) {
    const resp = await api.revolut.post("/exchange-rate", {
        body: { from, to, amount },
    });
    return { data: resp.exchangeRate?.to || null };
}

function RevolutDraftDialog(
    { dialogName = DIALOG_NAME, onSubmit, onCancel, draftData },
) {
    const shown = useSelector(s => s.dialogs[dialogName]);
    const [ accountId, setAccountId ] = useState("");
    const [ userAmountValue, setUserAmountValue ] = useState("");
    const [ draftCurrency, setDraftCurrency ] = useState(
        draftData?.currency.toUpperCase() || "",
    );

    const { data: accounts, isLoading: loadingAccounts } = useQuery(
        loadAccounts,
        [ shown ],
        {
            enabled: !!shown,
        },
    );

    const { data: userCurrency, isLoading: loadingUser } = useQuery(
        loadCounterpartyCurrency,
        [ draftData?.userId, shown ],
        {
            enabled: !!shown && !!draftData?.userId,
            initialData: null,
            params: {
                userId: draftData?.userId,
            },
        },
    );

    const { data: userAmount, isLoading: loadingAmount } = useQuery(
        loadPaymentAmount,
        [
            shown,
            userCurrency,
            draftData?.amount,
            draftCurrency,
        ],
        {
            initialData: null,
            enabled: !!shown
                && !!userCurrency
                && !!draftCurrency
                && draftCurrency !== userCurrency,
            params: {
                to: userCurrency?.toUpperCase(),
                amount: draftData?.amount,
                from: draftCurrency,
            },
        },
    );

    const [ reference, setReference ] = useState("");
    const loading = useMemo(
        () => !!loadingAccounts || !!loadingUser || !!loadingAmount,
        [ loadingAccounts, loadingUser, loadingAmount ],
    );

    const ref = useDictRef({
        accounts,
        onSubmit,
        onCancel,
        accountId,
        reference,
        draftData,
        userCurrency,
        userAmountValue,
        draftCurrency,
    });

    const submittable = useMemo(
        () => {
            return !!accountId && !!reference
                && (draftCurrency === userCurrency || !!userAmountValue);
        },
        [ accountId, reference, userAmountValue, userCurrency, draftCurrency ],
    );

    const onAccountChange = useCallback(
        (e) => setAccountId(e.target.value),
        [],
    );

    const onSubmitDraft = useCallback(
        () => {
            const data = {
                ...ref.draftData,
                accountId: ref.accountId,
                reference: ref.reference,
            };
            if (ref.draftCurrency !== ref.userCurrency) {
                data.currency = ref.userCurrency;
                data.amount = parseFloat(ref.userAmountValue);
            }
            ref.onSubmit && ref.onSubmit(data);
            setReference("");
            RevolutDraftDialog.hide();
        },
        // eslint-disable-next-line
        [],
    );

    const onCancelDraft = useCallback(
        () => {
            RevolutDraftDialog.hide();
            setReference("");
            ref.onCancel && ref.onCancel();
        },
        // eslint-disable-next-line
        [],
    );

    useUpdateEffect(
        () => {
            if (accounts) {
                setAccountId(accounts[0].id);
            }
        },
        [ accounts ],
    );

    useUpdateEffect(
        () => {
            setUserAmountValue("" + userAmount?.amount || "");
        },
        [ userAmount ],
    );

    useUpdateEffect(
        () => {
            if (draftData) {
                setReference(draftData.reference);
                setDraftCurrency(draftData.currency.toUpperCase());
            }
        },
        [ draftData ],
    );

    return (
        <Dialog
            name={dialogName}
            className="revolut-draft-dialog"
            triggerMode="click"
            closeOnBody={false}
            closeOnOverlay={false}>
            <h3>Create payment draft</h3>
            <NullForm>
                <TextField
                    autoComplete="off"
                    fullWidth
                    size="normal"
                    value={reference}
                    label="Payment Reference"
                    onChange={e => setReference(e.target.value)} />

                {accounts
                    && (
                        <FormControl fullWidth>
                            <InputLabel>Account to pay from</InputLabel>
                            <Select
                                disabled={loading}
                                fullWidth
                                size="normal"
                                value={accountId}
                                label="Account to pay from"
                                onChange={onAccountChange}>
                                {accounts.map(a => (
                                    <MenuItem
                                        key={a.id}
                                        value={a.id}
                                        children={a.label} />
                                ))}
                            </Select>
                        </FormControl>
                    )}

                {(!!draftCurrency && !!userCurrency
                    && draftCurrency !== userCurrency) && (
                    <TextField
                        autoComplete="off"
                        fullWidth
                        size="normal"
                        value={userAmountValue}
                        label={`Payment amount in PSE's currency (${userCurrency})`}
                        onChange={e => setUserAmountValue(e.target.value)} />
                )}
            </NullForm>

            <Button
                disabled={!submittable || loading}
                variant="contained"
                children="Create draft"
                onClick={onSubmitDraft} />
            <Button
                variant="text"
                children="Cancel"
                onClick={onCancelDraft} />

            {loading && <Loader absolute />}
        </Dialog>
    );
}

RevolutDraftDialog.show = (name = DIALOG_NAME) => {
    store.dispatch(ddUi.show(name));
};

RevolutDraftDialog.hide = (name = DIALOG_NAME) => {
    store.dispatch(ddUi.hide(name));
};

export default RevolutDraftDialog;
