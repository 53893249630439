import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import Loader from "common/src/components/Loader";
import LookCards from "common/src/components/look/Cards";
import Menu from "common/src/components/Menu";
import Pagination from "common/src/components/Pagination";
import Table from "common/src/components/table/Table";
import { ReactComponent as IconDownload } from "common/src/svg/download.svg";
import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg";
// import UserSelectorDialog from "../../dialog/UserSelectorDialog";
import ClickDialog from "./ClickDialog";
import CommissionDetails from "./CommissionDetails";
import CommissionDialog from "./CommissionHistory";

import AdvertiserSelector from "common/src/components/network/AdvertiserSelector";
import NetworkSelector from "common/src/components/network/NetworkSelector";
import UserSelector from "common/src/components/user/UserSelector";
// import CampaignSelector from "common/src/components/network/CampaignSelector"
import DateRangeSelector from "common/src/components/filter/DateRange";
import SearchFilter from "common/src/components/filter/Search";
import StatusSelector from "common/src/components/network/StatusSelector";
import PublicSelector from "common/src/components/PublicSelector";
import UserAttributionSelector from "../../filter/UserAttributionSelector";
import UserGroupsSelector from "../../filter/UserGroupsSelector";

import DataStore from "common/src/lib/DataStore";

import formatCurrency from "common/src/lib/format/currency";
import { ui as ddUi } from "common/src/store/dialogs";

// import * as partnerize from "./network/partnerize"
// import * as rakuten from "./network/rakuten"
// import * as cj from "./network/cj"
// import * as awin from "./network/awin"

import * as columns from "./datastore/commissions";

import api from "app/api";
import { getItemStatusClass, getStatusClass } from "app/lib/orderStatus";
import routes from "app/routes";
import hub from "common/src/hub";
import async from "common/src/lib/js/async";
import statesAreEqual from "common/src/lib/statesAreEqual";
import getUrl from "common/src/lib/url/get";
import state2query from "common/src/lib/url/state2query";
import { default as url2state, list2param } from "common/src/lib/url/url2state";
import { dateGroupItems, dateGroupNames } from "./common";

import {
    loadCommissions,
    loadCommissionsByAdvertiser,
    loadCommissionsByCampaign,
    loadCommissionsByContributor,
    loadCommissionsByDate,
    loadCommissionsByFri,
    loadCommissionsByLook,
    loadCommissionsByNetwork,
    loadCommissionsByPse,
    PER_PAGE,
} from "app/actions/page/commissions";
import CommissionProductDetails from "./CommissionProductDetails";

// const networkDetails = {
//     partnerize, rakuten, cj, awin
// };

const urlParams = [
    { name: "section", defaultValue: "all" },
    {
        name: "page",
        type: "int",
        defaultValue: 1,
        restore: page => Math.max(1, page) - 1,
        store: page => (page || 0) + 1,
    },
    { name: "start", stateName: "startDate" },
    { name: "end", stateName: "endDate" },
    { name: "q", stateName: "query" },
    { name: "withFri", type: "bool", defaultValue: null },
    { name: "withContributor", type: "bool", defaultValue: null },
    {
        name: "i",
        stateName: "infs",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "f",
        stateName: "fris",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "ifs",
        stateName: "contributors",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "pses",
        stateName: "pses",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "n",
        stateName: "networks",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "a",
        stateName: "advertisers",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "s",
        stateName: "statuses",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "c",
        stateName: "campaigns",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "ugs",
        stateName: "userGroups",
        type: "array",
        defaultValue: [],
        store: v => list2param(v),
    },
    {
        name: "g",
        stateName: "dateGroup",
        defaultValue: "month",
        restore: v => [ { id: v, name: dateGroupNames[v] } ],
        store: v => v ? v[0].id : null,
    },
];

const getUrlState = (props) => {
    return url2state(urlParams, props);
};

const getFilterUrl = (state) => {
    return getUrl(routes.reportCommissions, state2query(state, urlParams));
};

const renderSum = (gbpAmount, usdAmount) => {
    if (!gbpAmount && !usdAmount) {
        return "0";
    }

    const sum = [];
    gbpAmount && sum.push(formatCurrency(gbpAmount, "gbp", 2));
    usdAmount && sum.push(formatCurrency(usdAmount, "usd", 2));
    return sum.join(" + ");
};

const renderMoneyCol = (row, key, defaultCurrency = "gbp") => {
    if (row.gbp || row.usd) {
        const gbp = row?.gbp || {};
        const usd = row?.usd || {};
        return renderSum(gbp[key] || 0, usd[key] || 0);
    }
    return formatCurrency(row[key], defaultCurrency, 2);
};

const filterSetup = {
    all: {
        filters: [
            "date",
            "search",
            "pse",
            "fri",
            "contributor",
            "network",
            "advertiser",
            "campaign", /*"usergroup",*/
            "status",
            "attribution",
        ],
        options: {
            attribution: { withFri: "Look by", withContributor: "Link by" },
        },
    },
    products: {
        filters: [
            "date",
            "search",
            "pse",
            "fri",
            "contributor",
            "network",
            "advertiser",
            "campaign", /*"usergroup",*/
            "status",
            "attribution",
        ],
        options: {
            attribution: { withFri: "Look by", withContributor: "Link by" },
        },
    },
    bydate: {
        filters: [
            "dategroup",
            "date",
            "pse",
            "fri",
            "contributor", /*"usergroup",*/
            "network",
            "advertiser",
            "campaign",
            "attribution",
        ],
        options: {
            groupItems: dateGroupItems,
            attribution: { withFri: "Look by", withContributor: "Link by" },
        },
    },
    bypse: {
        filters: [
            "date",
            "network",
            "advertiser",
            "campaign", /*"usergroup"*/
        ],
        options: {},
    },
    byfri: {
        filters: [
            "date",
            "network",
            "advertiser",
            "campaign",
            /*"usergroup",*/ "fri",
        ],
        options: {},
    },
    bycontributor: {
        filters: [
            "date",
            "network",
            "advertiser",
            /*"usergroup",*/ "campaign",
            "contributor",
        ],
        options: {},
    },
    bynetwork: {
        filters: [
            "date",
            "advertiser",
            "campaign",
            "network", /*"usergroup",*/
            "pse",
            "fri",
            "contributor",
            "attribution",
        ],
        options: {
            attribution: {
                withFri: "Look by",
                withContributor: "Link by",
            },
        },
    },
    byadvertiser: {
        filters: [
            "date",
            "network",
            "campaign",
            "advertiser", /*"usergroup",*/
            "pse",
            "fri",
            "contributor",
            "attribution",
        ],
        options: {
            attribution: {
                withFri: "Look by",
                withContributor: "Link by",
            },
        },
    },
    bycampaign: {
        filters: [
            "date",
            "advertiser",
            "network",
            "campaign", /*"usergroup",*/
            "pse",
            "fri",
            "contributor",
            "attribution",
        ],
        options: {
            attribution: {
                withFri: "Look by",
                withContributor: "Link by",
            },
        },
    },
    bylook: {
        filters: [
            "date",
            "advertiser",
            "network",
            "campaign", /*"usergroup",*/
            "pse",
            "fri",
            "contributor",
        ],
        options: {},
    },
};

class PageCommissions extends React.Component {
    state = {
        section: "all",
        startDate: null,
        endDate: null,
        page: 0,
        loading: false,
        downloading: false,
        data: null,
        showDateSelector: false,

        clickInfo: null,
        historyOrder: null,

        fris: [],
        pses: [],
        contributors: [],
        networks: [],
        advertisers: [],
        campaigns: [],
        statuses: [],
        userGroups: [],
        query: "",
        withFri: null,
        withContributor: null,
        dateGroup: [ { id: "month", name: "Month" } ],
    };

    dataStore = null;
    _isMounted = false;

    constructor() {
        super();
        this.onRakutenItemStatusUpdate = this.onRakutenItemStatusUpdate.bind(
            this,
        );
    }

    componentDidMount() {
        this.dataStore = new DataStore();
        const state = getUrlState(this.props);
        this.setState(state, () => this.load());
        this._isMounted = true;
        hub.listen(
            "app",
            "order-item-status-update",
            this.onRakutenItemStatusUpdate,
        );
    }

    componentWillUnmount() {
        this.dataStore = null;
        this._isMounted = false;
        hub.remove(
            "app",
            "order-item-status-update",
            this.onRakutenItemStatusUpdate,
        );
    }

    componentDidUpdate() {
        const state = getUrlState(this.props);
        const keys = urlParams.map(p => p.stateName || p.name);
        if (!statesAreEqual(state, this.state, keys)) {
            async(() =>
                this.setState({ ...state, data: null }, () => this.load())
            );
        }
    }

    onRakutenItemStatusUpdate() {
        // console.log("on update")
        async(() => this.reload(), 500);
        // this.reload();
    }

    setFilter(state) {
        const newState = Object.assign({}, this.state, state, { page: 0 });
        const url = getFilterUrl(newState);
        this.props.history.push(url);
    }

    getFilters() {
        const {
            startDate,
            endDate,
            dateGroup,
            query,
            networks,
            campaigns,
            advertisers,
            statuses,
            fris,
            contributors,
            userGroups,
            withFri,
            withContributor,
            pses,
        } = this.state;
        return {
            query,
            startDate,
            endDate,
            group: dateGroup,
            networks,
            campaigns,
            advertisers,
            statuses,
            fris,
            contributors,
            userGroups,
            withFri,
            withContributor,
            pses,
        };
    }

    async load(asFile = false) {
        const { section, page } = this.state;
        const filters = this.getFilters();
        let data;
        this.setState({ loading: !asFile, downloading: asFile });

        switch (section) {
            case "all": {
                data = await loadCommissions(filters, page, asFile);
                break;
            }
            case "products": {
                data = await loadCommissions(filters, page, asFile, true);
                break;
            }
            case "bypse": {
                data = await loadCommissionsByPse(filters, asFile);
                break;
            }
            case "byfri": {
                data = await loadCommissionsByFri(filters, asFile);
                break;
            }
            case "bycontributor": {
                data = await loadCommissionsByContributor(filters, asFile);
                break;
            }
            case "bynetwork": {
                data = await loadCommissionsByNetwork(filters, asFile);
                break;
            }
            case "byadvertiser": {
                data = await loadCommissionsByAdvertiser(filters, asFile);
                break;
            }
            case "bycampaign": {
                data = await loadCommissionsByCampaign(filters, asFile);
                break;
            }
            case "bylook": {
                data = await loadCommissionsByLook(filters, asFile);
                break;
            }
            case "bydate": {
                data = await loadCommissionsByDate(filters, asFile);
                break;
            }
            default: {
                data = null;
                break;
            }
        }

        if (!asFile && this.state.section === section) {
            this.prepareDs(data);
            this.setState({ loading: false, data });
        }
        if (asFile) {
            this.setState({ loading: false, downloading: false });
        }
    }

    prepareDs(data) {
        const { section } = this.state;

        switch (section) {
            case "all": {
                const {
                    commissions,
                    commissionAmount,
                    saleAmount,
                    grossCommissionAmount,
                    grossSaleAmount,
                    revenue,
                } = data;
                this.dataStore.setRows(commissions);
                this.dataStore.setColumns(columns.list);
                this.dataStore.setCustomData({
                    commissionAmount,
                    saleAmount,
                    grossCommissionAmount,
                    grossSaleAmount,
                    revenue,
                });
                this.dataStore.trigger("change");
                break;
            }
            case "products": {
                const { commissions } = data;
                this.dataStore.setRows(commissions);
                this.dataStore.setColumns(columns.products);
                this.dataStore.trigger("change");
                break;
            }
            case "bypse": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.pse);
                this.dataStore.trigger("change");
                break;
            }
            case "byfri": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.fri);
                this.dataStore.trigger("change");
                break;
            }
            case "bycontributor": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.contributor);
                this.dataStore.trigger("change");
                break;
            }
            case "bynetwork": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.network);
                this.dataStore.trigger("change");
                break;
            }
            case "byadvertiser": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.advertiser);
                this.dataStore.trigger("change");
                break;
            }
            case "bycampaign": {
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.campaign);
                this.dataStore.trigger("change");
                break;
            }
            case "bydate": {
                const { dateGroup } = this.state;
                this.dataStore.setRows(data);
                this.dataStore.setColumns(columns.date);
                this.dataStore.setCustomData({ dateGroup });
                this.dataStore.trigger("change");
                break;
            }
            default: {}
        }
    }

    reload() {
        this.load();
    }

    async download() {
        this.load(true);
    }

    setDateRange(range) {
        const state = Object.assign({}, this.state, {
            startDate: range[0] ? moment(range[0]).format("YYYY-MM-DD") : null,
            endDate: range[1] ? moment(range[1]).format("YYYY-MM-DD") : null,
            page: 0,
        });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    setSection(section) {
        const state = Object.assign({}, { section, page: 0 });
        const url = getFilterUrl(state);
        this.setState({ data: null });
        this.props.history.push(url);
    }

    setPage(page) {
        const state = Object.assign({}, this.state, { page });
        const url = getFilterUrl(state);
        this.props.history.push(url);
    }

    async showCommissionClick(comm) {
        const clickInfo = await api.logProductClick
            .list({ where: { sid: { _eq: comm.clickId } } })
            .then(list => list[0] || null);
        this.setState({ clickInfo });
        this.props.dispatch(ddUi.show("click-info"));
    }

    showCommissionHistory(comm) {
        this.setState({ historyOrder: comm });
        this.props.dispatch(ddUi.show("commission-history"));
    }

    async showClickHistory(comm) {
        this.setFilter({ query: comm.clickId });
    }

    getFilterLink(type, value) {
        const state = this.state;
        switch (type) {
            case "click": {
                return getFilterUrl({ ...state, query: value });
            }
            case "click-log": {
                return getUrl(routes.reportClicks, { q: value });
            }
            case "advertiser": {
                return getFilterUrl({
                    ...state,
                    advertisers: [ { id: value } ],
                });
            }
            case "network": {
                return getFilterUrl({ ...state, networks: [ { id: value } ] });
            }
            case "fri": {
                return getFilterUrl({ ...state, fris: [ { id: value } ] });
            }
            case "pse": {
                return getFilterUrl({ ...state, pses: [ { id: value } ] });
            }
            case "contributor": {
                return getFilterUrl({
                    ...state,
                    contributors: [ { id: value } ],
                });
            }
            default: {
                return null;
            }
        }
    }

    applyFilterFromDetails(type, value) {
        switch (type) {
            case "click": {
                this.setFilter({ query: value });
                break;
            }
            case "advertiser": {
                this.setFilter({ advertisers: [ { id: value } ] });
                break;
            }
            case "network": {
                this.setFilter({ networks: [ { id: value } ] });
                break;
            }
            case "pse": {
                this.setFilter({ pses: [ { id: value } ] });
                break;
            }
            case "fri": {
                this.setFilter({ fris: [ { id: value } ] });
                break;
            }
            case "contributor": {
                this.setFilter({ contributors: [ { id: value } ] });
                break;
            }
            default: {}
        }
    }

    getNav() {
        const { loading, section, downloading } = this.state;
        const refreshCls = [
            "icon",
            "icon-svg-fill",
            loading ? "spinning" : "",
        ];
        const downloadCls = [ "icon", "icon-svg-fill" ];

        return [
            {
                name: "Sales",
                onClick: () => this.setSection("all"),
                active: section === "all",
            },
            {
                name: "Products",
                onClick: () => this.setSection("products"),
                active: section === "products",
            },
            {
                name: "By date",
                onClick: () => this.setSection("bydate"),
                active: section === "bydate",
            },
            {
                name: "By PSE",
                onClick: () => this.setSection("bypse"),
                active: section === "bypse",
            },
            /*{
                name: "By look creator",
                onClick: () => this.setSection("byfri"),
                active: section === "byfri"
            },
            {
                name: "By link creator",
                onClick: () => this.setSection("bycontributor"),
                active: section === "bycontributor"
            },*/
            {
                name: "By network",
                onClick: () => this.setSection("bynetwork"),
                active: section === "bynetwork",
            },
            {
                name: "By retailer",
                onClick: () => this.setSection("byadvertiser"),
                active: section === "byadvertiser",
            },
            /*{
                name: "By campaign",
                onClick: () => this.setSection("bycampaign"),
                active: section === "bycampaign"
            },
            {
                name: "By look",
                onClick: () => this.setSection("bylook"),
                active: section === "bylook"
            },*/
            section !== "bylook"
                ? {
                    icon: downloading
                        ? <Loader />
                        : <IconDownload className={downloadCls.join(" ")} />,
                    onClick: () => !downloading && this.download(),
                }
                : null,
            {
                icon: <IconRefresh className={refreshCls.join(" ")} />,
                onClick: () => this.reload(),
            },
        ];
    }

    renderFilters(filters, options = {}) {
        const {
            fris,
            pses,
            networks,
            advertisers,
            /*campaigns,*/ contributors,
            dateGroup,
            startDate,
            endDate,
            query,
            statuses,
            userGroups,
            withFri,
            withContributor,
        } = this.state;

        return (
            <>
                {filters.indexOf("dategroup") !== -1
                    && (
                        <PublicSelector
                            name="Group by"
                            cls="tag-selector-v2"
                            noSearch
                            single
                            mustHaveSelection
                            value={dateGroup}
                            items={options.groupItems}
                            filtered={options.groupItems}
                            onChange={dateGroup =>
                                this.setFilter({ dateGroup })} />
                    )}
                {filters.indexOf("date") !== -1
                    && (
                        <DateRangeSelector
                            name="Date range"
                            minDate={new Date("2020-01-01T00:00:00")}
                            maxDate={new Date()}
                            value={startDate ? [ startDate, endDate ] : []}
                            onChange={r => this.setDateRange(r)} />
                    )}
                {filters.indexOf("search") !== -1
                    && (
                        <SearchFilter
                            value={query}
                            onChange={query => this.setFilter({ query })} />
                    )}
                {filters.indexOf("pse") !== -1
                    && (
                        <UserSelector
                            className="multiline"
                            name="PSE"
                            group={[ "FRI", "GPS" ]}
                            searchGroup=""
                            template={u => (
                                <>
                                    {u.givenName} {u.familyName}
                                    <br />
                                    <span className="tag-selector-subname">
                                        {u.handle ? "@" + u.handle : u.email}
                                    </span>
                                </>
                            )}
                            value={pses}
                            onChange={pses => this.setFilter({ pses })} />
                    )}
                {filters.indexOf("fri") !== -1
                    && (
                        <UserSelector
                            className="multiline"
                            name="Look by"
                            group="FRI"
                            searchGroup=""
                            template={u => (
                                <>
                                    {u.givenName} {u.familyName}
                                    <br />
                                    <span className="tag-selector-subname">
                                        {u.handle ? "@" + u.handle : u.email}
                                    </span>
                                </>
                            )}
                            value={fris}
                            onChange={fris => this.setFilter({ fris })} />
                    )}
                {filters.indexOf("contributor") !== -1
                    && (
                        <UserSelector
                            className="multiline"
                            name="Link by"
                            group="Contributor"
                            searchGroup=""
                            template={u => (
                                <>
                                    {u.givenName} {u.familyName}
                                    <br />
                                    <span className="tag-selector-subname">
                                        {u.handle ? "@" + u.handle : u.email}
                                    </span>
                                </>
                            )}
                            value={contributors}
                            onChange={contributors =>
                                this.setFilter({ contributors })} />
                    )}
                {filters.indexOf("network") !== -1
                    && (
                        <NetworkSelector
                            name="Network"
                            value={networks}
                            onChange={networks =>
                                this.setFilter({ networks })} />
                    )}
                {filters.indexOf("advertiser") !== -1
                    && (
                        <AdvertiserSelector
                            name="Retailer"
                            value={advertisers}
                            onChange={advertisers =>
                                this.setFilter({ advertisers })} />
                    )}
                {
                    /*{filters.indexOf("campaign") !== -1 &&
                    <CampaignSelector
                        name="Campaign"
                        value={campaigns}
                        onChange={campaigns => this.setFilter({ campaigns })} />}*/
                }
                {filters.indexOf("status") !== -1
                    && (
                        <StatusSelector
                            name="Status"
                            value={statuses}
                            onChange={statuses =>
                                this.setFilter({ statuses })} />
                    )}
                {filters.indexOf("usergroup") !== -1
                    && (
                        <UserGroupsSelector
                            name="User group"
                            value={userGroups}
                            onChange={userGroups =>
                                this.setFilter({ userGroups })} />
                    )}
                {filters.indexOf("attribution") !== -1
                    && (
                        <UserAttributionSelector
                            name="User Attribution"
                            value={{ withFri, withContributor }}
                            groups={options.attribution}
                            onChange={value => this.setFilter(value)} />
                    )}
            </>
        );
    }

    renderCommissionDetails(r) {
        return (
            <CommissionDetails
                commission={r}
                onShowClickInfo={r => this.showCommissionClick(r)}
                onShowCommissionHistory={r => this.showCommissionHistory(r)}
                onShowClickHistory={r => this.showClickHistory(r)}
                getFilterLink={(t, v) => this.getFilterLink(t, v)}
                onFilter={(t, v) => this.applyFilterFromDetails(t, v)} />
        );
    }

    renderProductDetails(r) {
        return <CommissionProductDetails row={r} />;
    }

    renderCommissions() {
        const { commissions, count } = this.state.data;
        const { page } = this.state;

        return (
            <>
                <div className="page-commissions-grid-wrapper">
                    <Table
                        variant="slim"
                        dataStore={this.dataStore}
                        expandKey="orderId"
                        expandable
                        summary="both"
                        getRowClass={r => "status-" + getStatusClass(r)}
                        renderDetails={r => this.renderCommissionDetails(r)} />
                </div>
                {commissions.length === 0 && (
                    <div className="empty">No orders found</div>
                )}
                <Pagination
                    page={page}
                    perPage={PER_PAGE}
                    count={count}
                    showCount
                    onChange={page => this.setPage(page)} />
            </>
        );
    }

    renderProducts() {
        const { commissions, count } = this.state.data;
        const { page } = this.state;

        return (
            <>
                <div className="page-commissions-grid-wrapper">
                    <Table
                        variant="slim"
                        dataStore={this.dataStore}
                        expandKey={"sku"}
                        expandable={r =>
                            r.order.networkId === "rakuten"
                            || r.order.networkId === "partnerize"
                            || r.order.networkId === "cj"}
                        // summary="both"
                        getRowClass={r =>
                            "status-" + getItemStatusClass(r.order, r)}
                        renderDetails={r =>
                            r.order.networkId === "rakuten"
                                || r.order.networkId === "partnerize"
                                || r.order.networkId === "cj"
                                ? this.renderProductDetails(r)
                                : null} />
                </div>
                {commissions.length === 0 && (
                    <div className="empty">No orders found</div>
                )}
                <Pagination
                    page={page}
                    perPage={PER_PAGE}
                    count={count}
                    showCount
                    onChange={page => this.setPage(page)} />
            </>
        );
    }

    renderCommissionsByDate() {
        const commissions = this.state.data;
        const { dateGroup } = this.state;
        const chartDateFormats = {
            "year": "YYYY",
            "month": "MMM YY",
            "day": "D/M/YY",
        };

        const chartData = commissions.map(c => {
            let value = c.commissionAmount;
            if (value > -1 && value < 1) {
                value = 0;
            }
            const data = {
                id: c.date_group,
                name: moment(c.date_group).format(
                    chartDateFormats[dateGroup[0].id],
                ),
                value,
            };
            return data;
        }).reverse();

        return (
            <>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        height={300}
                        data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            angle={-60}
                            dy={20}
                            height={50}
                            dataKey="name"
                            margin={{ top: 20 }} />
                        <YAxis />
                        <Tooltip
                            formatter={(v, n) => formatCurrency(v, "gbp")} />
                        <Bar
                            dataKey="value"
                            fill="#62939a"
                            name="GBP"
                            label="GBP" />
                    </BarChart>
                </ResponsiveContainer>
                <Table dataStore={this.dataStore} />
                {commissions.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderCommissionsByPse() {
        const commissions = this.state.data;

        return (
            <>
                <Table dataStore={this.dataStore} />
                {commissions.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderCommissionsByFri() {
        const commissions = this.state.data;

        return (
            <>
                <Table dataStore={this.dataStore} />
                {commissions.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderCommissionsByContributor() {
        const commissions = this.state.data;
        return (
            <>
                <Table dataStore={this.dataStore} />
                {commissions.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderCommissionsByNetwork() {
        const commissions = this.state.data;
        return (
            <>
                <Table dataStore={this.dataStore} />
                {commissions.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderCommissionsByAdvertiser() {
        const rows = this.state.data;
        return (
            <>
                <Table dataStore={this.dataStore} />
                {rows.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderCommissionsByCampaign() {
        const commissions = this.state.data;

        return (
            <>
                <Table dataStore={this.dataStore} />
                {commissions.length === 0 && (
                    <div className="empty">No commissions found</div>
                )}
            </>
        );
    }

    renderLookFooter(l) {
        const comms = l.commissions;
        return renderMoneyCol(comms, "saleAmount") + " / "
            + renderMoneyCol(comms, "commissionAmount");
    }

    renderCommissionsByLook() {
        const looks = this.state.data;

        if (!looks) {
            return null;
        }

        return (
            <LookCards
                looks={looks}
                look={l => ({ footer: this.renderLookFooter(l) })}
                product={{
                    layers: [ "retailer" ],
                }} />
        );
    }

    render() {
        const menu = this.getNav();
        const { section, data, loading, clickInfo, historyOrder } = this.state;

        return (
            <>
                <div className="page page-commissions">
                    <div className="toolbar">
                        <div className="toolbar-title">
                            Sales & Commissions
                        </div>
                        <Menu menu={menu} className="right" />
                    </div>

                    {loading && <Loader size={64} />}

                    <div className="page-commissions-layout">
                        <div className="left">
                            {this.renderFilters(
                                filterSetup[section].filters,
                                filterSetup[section].options,
                            )}
                        </div>
                        <div className="right">
                            {(section === "all" && data)
                                && this.renderCommissions()}
                            {(section === "products" && data)
                                && this.renderProducts()}
                            {(section === "bypse" && data)
                                && this.renderCommissionsByPse()}
                            {(section === "byfri" && data)
                                && this.renderCommissionsByFri()}
                            {(section === "bycontributor" && data)
                                && this.renderCommissionsByContributor()}
                            {(section === "bynetwork" && data)
                                && this.renderCommissionsByNetwork()}
                            {(section === "byadvertiser" && data)
                                && this.renderCommissionsByAdvertiser()}
                            {(section === "bycampaign" && data)
                                && this.renderCommissionsByCampaign()}
                            {(section === "bylook" && data)
                                && this.renderCommissionsByLook()}
                            {(section === "bydate" && data)
                                && this.renderCommissionsByDate()}
                        </div>
                    </div>
                </div>
                <ClickDialog click={clickInfo} />
                <CommissionDialog
                    order={historyOrder}
                    onClose={() => this.setState({ history: null })} />
            </>
        );
    }
}

export default connect()(PageCommissions);
