import { batch } from "react-redux";
import client from "common/src/api/hasura/client";
import getUserContext from "common/src/api/getUserContext";
import gql from "graphql-tag";
import generator from "common/src/api/hasura/generate";
import normalizeLook from "common/src/api/normalize/look";
import user from "common/src/user";
import prepareWhere from "common/src/lib/prepareWhere";
import { prepareGraph } from "common/src/api/hasura/add";
import store from "app/store";
import hub from "common/src/hub";
import api from "app/api";
import uuidReg from "common/src/lib/uuidReg";
import { data as looksData, sets as looksSets } from "common/src/store/looks";
import { referencedProductsLoader } from "./productReference";

let orderingTagId = null;
let orderingLookIds = null;
let orderingResetTmt = null;

function getCommissionRates() {
    const state = store.getState();
    return state.catalogue?.data?.commissionRates || null;
}

function setTagOrderingCache(tagId, lookIds) {
    orderingTagId = tagId;
    orderingLookIds = lookIds;

    if (orderingResetTmt !== null) {
        window.clearTimeout(orderingResetTmt);
        orderingResetTmt = null;
    }

    // reset in three minutes
    orderingResetTmt = window.setTimeout(() => {
        orderingTagId = null;
        orderingLookIds = null;
        orderingResetTmt = null;
    }, 1000 * 60 * 3);
}

function lcf(str) {
    return str[0].toLowerCase() + str.substring(1);
}

const lookGraph = `
    id
    consultationId
    listId
    title
    description
    path
    createdAt
    updatedAt
    layouts
    productMode
    published
    <Admin|FRI|GPS>
    deleted
    </Admin|FRI|GPS>
    fri { id avatar handle ---friFields--- }
    ---lookFields---
    products(order_by: {position: asc}) { 
        id
        lookId
        name
        description
        images
        url
        retailer
        catalogueId
        catalogueProductReference {
            productId
            images
        }
        position
        ---productFields---
    }
`;

const { query, queryWithCount } = generator.list("Look");

const lookSavedGraph = `
    User_SavedLook(where: { lookId: { _in: $lookIds }, userId: { _eq: "#userId"}}) {
        lookId
    }
`;

const productSavedGraph = `
    User_SavedProduct(where: {  productId: { _in: $productIds }, 
                                userId: { _eq: "#userId"}}) {
        productId
    }
`;

const productSelectedGraph = `
    UserProduct(where: {  productId: { _in: $productIds }, 
                            customerId: { _eq: "#userId"}}) {
        productId
        sizes
    }
`;

const productReactionUserGraph = `
    ,userId: { _eq: $reactionsUserId }
`;

const productReactionGraph = `
    Consultation_Customer_Reaction(where: { 
                                    productId: { _in: $productIds }
                                    ---productReactionUser---}) {
        id
        consultationId
        productId
        userId
        reaction
    }
`;

const myLastMessageGraph = `
    Chat_Message(where: { productId: { _in: $productIds }, 
                          action: { _is_null: true },
                          userId: { _eq: "#userId" }},
                 order_by: { createdAt: desc }) {
        message 
        createdAt
        productId
    }
`;

const dataQueryVariablesTpl = {
    lookIds: "$lookIds: [uuid!]!",
    productIds: "$productIds: [uuid!]!",
    reactionsUserId: "$reactionsUserId: uuid!",
    regions: "$regions: [String!]!",
};

//---lookTagsGraph---
//---productTagsGraph---
//---productStockGraph---
const dataQueryTpl = `
    query getLookData(---variables---) {
        
        ---lookSavedGraph---
        
        ---productSavedGraph---
        ---productSelectedGraph---
        ---productReactionGraph---
        
        ---productMyLastMessage---
    }
`;

const prepareQueryVariables = (query, templates) => {
    const variables = [];
    for (let name in templates) {
        if (query.indexOf("$" + name) !== -1) {
            variables.push(templates[name]);
        }
    }
    return query.replace("---variables---", variables.join(", "));
};

const assignTags = (itemsMap, tags, idParentKey, idKey, tagsKey) => {
    let k;
    for (k in itemsMap) {
        itemsMap[k].forEach((item) => {
            if (!item[tagsKey]) {
                item[tagsKey] = [];
            }
        });
    }
    tags.forEach((t) => {
        if (!t || !t[idParentKey]) {
            return;
        }
        const itemIds = t[idParentKey].map((r) => r[idKey]);
        delete t[idParentKey];
        itemIds.forEach((itemId) => {
            if (!itemsMap[itemId]) {
                //console.log(`${idParentKey} ${itemId} not found`)
                return;
            }
            itemsMap[itemId].forEach((item) => {
                item[tagsKey].push(t);
            });
        });
    });
};

const assignSaved = (itemsMap, saved, idKey) => {
    saved.forEach((s) => {
        const id = s[idKey];
        if (!itemsMap[id]) {
            return;
        }
        itemsMap[id].forEach((item) => {
            item.saved = true;
        });
    });
};

const assignReaction = (itemsMap, reactions, idKey) => {
    reactions.forEach((r) => {
        const id = r[idKey];
        if (!itemsMap[id]) {
            return;
        }
        itemsMap[id].forEach((item) => {
            item.reaction = r.reaction;
        });
    });
};

const assignSelected = (itemsMap, saved, idKey) => {
    saved.forEach((s) => {
        const id = s[idKey];
        if (!itemsMap[id]) {
            return;
        }
        itemsMap[id].forEach((item) => {
            item.selected = true;
            item.selectedSizes = s.sizes;
        });
    });
};

const assignLastMessage = (productMap, messages) => {
    let k;
    for (k in productMap) {
        productMap[k].forEach((item) => {
            if (!item.myLastMessage) {
                const msg = messages.find((m) => m.productId === item.id);
                if (msg) {
                    item.myLastMessage = msg;
                }
            }
        });
    }
};

export const lookDataLoader = async (items, opt = {}) => {
    const lookMap = {};
    const productMap = {};
    const lookIds = [];
    const productIds = [];
    const referenceIds = [];
    const referenceRegions = {};
    const reactionsUserId =
        opt.reactionsUserId === false
            ? false
            : opt.reactionsUserId || user.id();

    if (user.loggedIn() === null) {
        await hub.promise("app-auth", "stateChange");
    }

    if (!user.loggedIn()) {
        opt.savedLooks = false;
        opt.savedProducts = false;
    } else {
        if (user.current() === null) {
            await hub.promise("app-auth", "info-loaded");
        }
    }

    await hub.promise("app-auth", "geo-loaded");

    items.forEach((item) => {
        if (!lookMap[item.id]) {
            lookMap[item.id] = [];
        }
        lookMap[item.id].push(item);

        lookIds.push(item.id);
        item.products.forEach((p) => {
            productIds.push(p.id);
            if (p.catalogueProductReference) {
                const pr = p.catalogueProductReference;
                referenceIds.push(pr.productId);
                referenceRegions[pr.productId] = pr.region;
            }

            if (!productMap[p.id]) {
                productMap[p.id] = [];
            }
            productMap[p.id].push(p);
        });
    });

    let dataQuery = dataQueryTpl;

    dataQuery = dataQuery.replace(
        "---lookSavedGraph---",
        opt.savedLooks ? lookSavedGraph : ""
    );
    dataQuery = dataQuery.replace(
        "---productSavedGraph---",
        opt.savedProducts ? productSavedGraph : ""
    );
    dataQuery = dataQuery.replace(
        "---productSelectedGraph---",
        user.loggedIn() ? productSelectedGraph : ""
    );
    dataQuery = dataQuery.replace(
        "---productReactionGraph---",
        opt.productReactions ? productReactionGraph : ""
    );
    dataQuery = dataQuery.replace(
        "---productReactionUser---",
        reactionsUserId === false ? "" : productReactionUserGraph
    );
    dataQuery = dataQuery.replace(
        "---productMyLastMessage---",
        opt.myLastMessage ? myLastMessageGraph : ""
    );

    const emptyId = "00000000-0000-0000-0000-000000000000";
    dataQuery = dataQuery.replace(/#userId/g, user.id() || emptyId);

    const context = await getUserContext();
    const authRole = context.headers["X-Hasura-Role"];
    const variables = {
        productIds,
        lookIds,
        reactionsUserId,
    };

    if (referenceIds.length > 0) {
        const { data: catalogueProducts } = await referencedProductsLoader({
            productIds: referenceIds,
            productRegions: referenceRegions,
            offset: 0,
            limit: referenceIds.length,
        });
        items.forEach((item) => {
            item.products.forEach((p) => {
                const refId = p.catalogueProductReference.productId;
                const refProduct = catalogueProducts.find(
                    (p) => p.id === refId
                );
                if (refProduct) {
                    const id = p.id;
                    const images = p.images;
                    const cdnImages = p.cdnImages;
                    Object.assign(p, refProduct);
                    p.referencedProduct = refProduct;
                    p.originalImages = refProduct.images;
                    p.cdnImages = cdnImages;
                    p.images = images;
                    p.id = id;
                    p.referencedProductId = refId;
                }
            });
        });
    }
    const dataResponse = await client.query({
        query: gql(
            prepareQueryVariables(
                prepareGraph(authRole, dataQuery),
                dataQueryVariablesTpl
            )
        ),
        variables,
        context,
    });

    if (opt.savedLooks) {
        const { User_SavedLook } = dataResponse.data;
        User_SavedLook && assignSaved(lookMap, User_SavedLook, "lookId");
    }
    if (opt.savedProducts) {
        const { User_SavedProduct } = dataResponse.data;
        User_SavedProduct &&
            assignSaved(productMap, User_SavedProduct, "productId");
    }
    if (opt.productReactions) {
        const { Consultation_Customer_Reaction } = dataResponse.data;
        Consultation_Customer_Reaction &&
            assignReaction(
                productMap,
                Consultation_Customer_Reaction,
                "productId"
            );
    }
    if (user.loggedIn()) {
        const { UserProduct } = dataResponse.data;
        UserProduct && assignSelected(productMap, UserProduct, "productId");
    }
    if (opt.myLastMessage) {
        const { Chat_Message } = dataResponse.data;
        Chat_Message && assignLastMessage(productMap, Chat_Message);
    }

    return items;
};

export const lookLoader = async (options) => {
    let { limit = 15, offset = 0, tagId } = options;
    const {
        order,
        listId = null,
        tagType = null,
        lookFields = "",
        productFields = "",
        friFields = "",
        cacheSet = null,
        withProductTags = true,
        withLookTags = false,
        withSavedLooks = false,
        withSavedProducts = false,
        withProductStock = true,
        withCount = false,
        withReactions = false,
        reactionsUserId = null,
        withMyLastMessage = false,
    } = options;

    const where = prepareWhere(options.where || {});

    let listLookIds = [];
    let listLooksCount = 0;
    let tagLookIds = [];
    let tagLooksCount = 0;

    if (listId) {
        const listLooksWhere = {
            look: where,
        };
        if (listId.match(uuidReg)) {
            listLooksWhere.listId = { _eq: listId };
        } else {
            listLooksWhere.lookList = {
                textId: { _eq: listId },
            };
        }
        listLookIds = await api.lookListOrdering
            .list(
                {
                    offset,
                    limit,
                    order: { position: "desc" },
                    where: listLooksWhere,
                },
                "lookId"
            )
            .then((list) => list.map((l) => l.lookId));
        if (listLookIds.length === 0) {
            return withCount ? { items: [], count: 0 } : [];
        }
        if (withCount) {
            listLooksCount = await api.lookListOrdering
                .count({ where: listLooksWhere })
                .then((r) => r.count);
        }

        where.id = { _in: listLookIds };
        limit = 1000;
        offset = 0;
    } else {
        if (!where.listId) {
            where.listId = { _is_null: true };
        }
    }

    if (!listId && tagId && tagType && tagType !== "User") {
        if (!tagId.match(uuidReg)) {
            tagId = await api[lcf(tagType)]
                .list({ where: { path: { _eq: tagId } }, limit: 1 }, "id")
                .then((list) => list[0])
                .then((tag) => (tag ? tag.id : null));
            if (tagId === null) {
                tagId = "00000000-0000-0000-0000-000000000000";
            }
        }

        if (orderingTagId === tagId) {
            if (orderingLookIds.length === 0) {
                return withCount ? { items: [], count: 0 } : [];
            }
            tagLookIds = orderingLookIds;
            where.id = { _in: orderingLookIds };
            tagLooksCount = orderingLookIds.length;
        } else {
            const isProductTag =
                tagType === "Designer" || tagType === "ProductStyle";
            const tagApi = (isProductTag ? "product" : "look") + tagType;
            const tagApiField =
                tagType[0].toLowerCase() + tagType.substring(1) + "Id";
            const tagLooksWhere = isProductTag
                ? { product: { look: where } }
                : { look: where };
            tagLooksWhere[tagApiField] = { _eq: tagId };

            tagLookIds = await api[tagApi]
                .list(
                    {
                        order: { ordering: "desc" },
                        where: tagLooksWhere,
                    },
                    isProductTag ? "product { lookId }" : "lookId"
                )
                .then((list) =>
                    list.map((l) =>
                        isProductTag ? l.product.lookId : l.lookId
                    )
                )
                .then((list) =>
                    list.filter((id, inx, self) => self.indexOf(id) === inx)
                );

            setTagOrderingCache(tagId, tagLookIds);

            if (tagLookIds.length === 0) {
                return withCount ? { items: [], count: 0 } : [];
            }

            tagLooksCount = tagLookIds.length;

            where.id = { _in: tagLookIds };
            //limit = 1000;
            //offset = 0;
        }
    }

    const lookQuery = (withCount ? queryWithCount : query)
        .replace("---graph---", lookGraph)
        .replace("---lookFields---", lookFields)
        .replace("---productFields---", productFields)
        .replace("---friFields---", friFields)
        .replace("---countGraph---", "count");
    const context = await getUserContext();
    const authRole = context.headers["X-Hasura-Role"];
    const looksResponse = await client.query({
        query: gql(prepareGraph(authRole, lookQuery)),
        variables: { where, order, limit, offset },
        context,
    });

    const looksRaw = looksResponse.data["Look"];
    let items = listId
        ? listLookIds.map((id) => looksRaw.find((l) => l.id === id))
        : tagId && tagType && tagType !== "User"
        ? tagLookIds.map((id) => looksRaw.find((l) => l.id === id))
        : looksRaw;
    items = items.filter((item) => !!item);
    const count = withCount
        ? listId
            ? listLooksCount
            : tagId && tagType && tagType !== "User"
            ? tagLooksCount
            : looksResponse.data["Look_aggregate"]["aggregate"]["count"]
        : 0;

    if (
        items.length &&
        (withLookTags ||
            withProductTags ||
            withSavedLooks ||
            withSavedProducts ||
            withMyLastMessage)
    ) {
        await lookDataLoader(items, {
            lookTags: withLookTags,
            productStock: withProductStock,
            productTags: withProductTags,
            savedLooks: withSavedLooks && user.loggedIn(),
            savedProducts: withSavedProducts && user.loggedIn(),
            productReactions: withReactions,
            reactionsUserId,
            myLastMessage: withMyLastMessage,
        });
        const looks = items.map((l) => normalizeLook(l));

        // looks.forEach(look => {
        //     look.products = look.products.map(p => applyCommissionRates(p, commissionRates));
        // })

        if (cacheSet) {
            batch(() => {
                store.dispatch(looksData.looks.merge(looks));
                store.dispatch(looksSets.looks.init(cacheSet));
                store.dispatch(
                    looksSets.looks[offset === 0 ? "set" : "append"]({
                        name: cacheSet,
                        value: looks.map((l) => l.id),
                    })
                );
            });
        }

        return withCount ? { items: looks, count } : looks;
    } else {
        const looks = items.map((l) => normalizeLook(l));

        if (cacheSet) {
            batch(() => {
                store.dispatch(looksData.looks.merge(looks));
                store.dispatch(looksSets.looks.init(cacheSet));
                store.dispatch(
                    looksSets.looks[offset === 0 ? "set" : "append"]({
                        name: cacheSet,
                        value: looks.map((l) => l.id),
                    })
                );
            });
        }

        return withCount ? { items: looks, count } : looks;
    }
};
