import { configureStore } from "@reduxjs/toolkit";

export default function prepare(all) {
    let reducers = {},
        preloaded = {},
        key;

    for (key in all) {
        reducers[key] = all[key].reducer;
        preloaded[key] = all[key].initialState;
    }

    const store = configureStore(
        {
            reducer: reducers,
            devTools: true,
            preloadedState: preloaded,
            middleware: (getDefaultMiddleware) =>
                getDefaultMiddleware({
                    serializableCheck: false,
                }),
        },
    );

    return store;
}
