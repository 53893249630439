import { getOrderItemStatusName } from "app/lib/orderStatus";
import df from "common/src/lib/date/formats";
import formatCurrency from "common/src/lib/format/currency";
import moment from "moment";

export function getProductRows(order) {
    // const details = JSON.parse(order.details);

    return order.partnerizeItems.map(item => {
        const status = item.manualStatus || item.status;
        const saleAmount = status === "rejected"
            ? 0
            : item.itemValue * item.quantity;
        const commissionAmount = status === "rejected"
            ? 0
            : item.itemCommission * item.quantity;
        return {
            id: item.id,
            conversionId: item.conversionItemId,
            order,
            sku: item.sku,
            name: item.name,
            brand: item.brand,
            quantity: item.quantity,
            saleAmount,
            commissionAmount,
            currency: item.currency,
            status,
            item_status: status,
            manualStatus: item.manualStatus,
            rejectReason: item.reject_reason,
        };
    });
}

export async function getProducts(comm, renderItemDetails) {
    const details = JSON.parse(comm.details);
    const currency = details.currency.toLowerCase();
    const cols = [
        { id: "name", name: "Product" },
        { id: "brand", name: "Brand" },
        { id: "quantity", name: "Quantity" },
        {
            id: "sale",
            name: "Sale",
            render: i => formatCurrency(i.sale, currency, 2),
        },
        {
            id: "commission",
            name: "TF Commission",
            render: i => formatCurrency(i.commission, currency, 2),
        },
        { id: "item_status", name: "Affiliate status" },
        {
            id: "status",
            name: "TF Order status",
            render: i => getOrderItemStatusName(comm, i),
        },
        { id: "reject_reason", name: "Reject reason" },
    ];

    const rows = comm.partnerizeItems.map(i => {
        const status = i.status;
        let sale = i.itemValue * i.quantity;
        let commission = i.itemCommission * i.quantity;

        if (status !== "new" && status !== "pending" && status !== "approved") {
            sale = 0;
            commission = 0;
        }

        return {
            id: i.id,
            conversionId: i.conversionItemId,
            name: i.name,
            brand: i.brand,
            quantity: i.quantity,
            sale,
            commission,
            item_status: status,
            manualStatus: i.manualStatus,
            reject_reason: i.reject_reason,
        };
    });
    // const rows = details.conversion_items.map(i => {
    //     return {
    //         name: i.meta_data.product || i.meta_data.name,
    //         brand: i.meta_data.item_product_group || i.meta_data.brand,
    //         quantity: i.quantity,
    //         sale: parseFloat(i.item_value) * parseInt(i.quantity),
    //         commission: parseFloat(i.item_publisher_commission)
    //             * parseInt(i.quantity),
    //         item_status: i.item_status,
    //         status: i.item_status,
    //         reject_reason: i.reject_reason,
    //     };
    // });

    return {
        cols,
        rows,
        expandable: true,
        expandKey: "id",
        renderDetails: item => renderItemDetails(comm, item),
    };
}

export function getStatusClass(comm) {
    switch (comm.status) {
        case "approved": {
            return "ok";
        }
        case "mixed": {
            return "okeish";
        }
        case "rejected": {
            return "notok";
        }
        case "pending": {
            return "pending";
        }
        default: {
            return "";
        }
    }
}

export function hasHistory(order) {
    return order.snapshots && order.snapshots.length > 0;
}

export function getHistory(order) {
    const cols = [
        {
            id: "importedAt",
            name: "Import date",
            render: c => moment(c.importedAt).format(df.full),
        },
        { id: "status", name: "Status" },
    ];
    const rows = order.snapshots;

    return { cols, rows };
}

export function hasCorrections(order) {
    return false;
}
