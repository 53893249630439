import normalizeProduct from "./product";
import { initialLayout } from "common/src/store/look/editor";

export default function (look) {
    if (look.__normalized) {
        return look;
    }

    // if (look.styles) {
    //     look.styles = look.styles.map(i => i.style ? i.style : i);
    // }

    // if (look.seasons) {
    //     look.seasons = look.seasons.map(i => i.season ? i.season : i);
    // }

    // if (look.occasions) {
    //     look.occasions = look.occasions.map(i => i.occasion ? i.occasion : i);
    // }

    // if (look.locations) {
    //     look.locations = look.locations.map(i => i.location ? i.location : i);
    // }

    // if (look.hiddenTags) {
    //     look.hiddenTags = look.hiddenTags.map(i => i.hiddenTag ? i.hiddenTag : i);
    // }

    if (look.products) {
        look.products = look.products.map(normalizeProduct);
    }

    if (look.layouts) {
        look.layouts = JSON.parse(look.layouts);
    }

    if (look.products) {
        look.products.forEach((p) => {
            if (!look.layouts[p.id] && typeof look.layouts !== "string") {
                look.layouts[p.id] = initialLayout;
            }
        });
    }

    if (look.saved) {
        if (look.saved !== true) {
            look.saved = look.saved.length > 0;
        }
    } else {
        look.saved = false;
    }

    if (look.picked) {
        if (look.picked.length === 0) {
            look.picked = null;
        } else {
            look.picked = look.picked[0];
        }
    }

    look.__normalized = true;
    return look;
}
