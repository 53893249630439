import s3url, { url2key } from "common/src/lib/image/s3url";
import store from "app/store";
import getClickDomain from "common/src/lib/url/clickDomain";

export default function (product) {
    if (product.__normalized) {
        return product;
    }

    if (product.productStyles) {
        product.productStyles = product.productStyles.map((i) =>
            i.productStyle ? i.productStyle : i
        );
    }

    if (product.designers) {
        product.designers = product.designers.map((i) =>
            i.designer ? i.designer : i
        );
    }

    if (product.sizes && product.sizes[0] === "[") {
        try {
            product.sizes = JSON.parse(product.sizes);
        } catch (err) {
            //console.error(err);
            //console.log(product.sizes);
            product.sizes = [];
        }
    } else {
        product.sizes = [];
    }

    if (product.image) {
        if (product.image[0] === "{") {
            product.image = JSON.parse(product.image);
            if (!product.image.key) {
                product.image.key = url2key(product.image.src);
            }
            if (!product.image.src) {
                product.image.src = s3url(product.image.key);
            }
        } else {
            product.image = s3url(product.image);
        }
    }

    if (product.images && typeof product.images === "string") {
        product.images = JSON.parse(product.images);
    } else if (!product.images) {
        product.images = [];
    }

    if (product.originalImages && typeof product.originalImages === "string") {
        product.originalImages = JSON.parse(product.originalImages);
    }

    if (product.image) {
        product.images.unshift(product.image);
    }

    product.images = product.images.filter((i) => !!i);

    if (product.saved) {
        if (product.saved !== true) {
            product.saved = product.saved.length > 0;
        }
    } else {
        product.saved = false;
    }

    // if (product.stock) {
    //     if (product.stock.sizes) {
    //         product.stock.sizes = JSON.parse(product.stock.sizes);
    //     } else product.stock.sizes = [];
    //     for (let k in [
    //         "retailer",
    //         "price",
    //         "salePrice",
    //         "currency",
    //         "availability",
    //         "sizes",
    //     ]) {
    //         product[k] = product.stock[k];
    //     }
    // }

    if (product.customerReactions) {
        product.reaction = product.customerReactions[0]?.reaction;
    }

    const state = store.getState();
    const country =
        state.user && state.user.geo ? state.user.geo.country || null : null;
    const refUserId =
        state.app && state.app.session
            ? state.app.session.referenceUserId
            : null;
    const params = [];
    const clickDomain = getClickDomain();

    product.clickUrl = `https://${clickDomain}/p/${product.id}`;

    if (country) {
        params.push(["c", country]);
        //product.clickUrl += `?c=${ country }`;
    }
    if (refUserId) {
        params.push(["refid", refUserId]);
    }

    if (params.length > 0) {
        product.clickUrl +=
            "?" + params.map((p) => `${p[0]}=${p[1]}`).join("&");
    }

    product.__normalized = true;

    return product;
}
