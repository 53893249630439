// const tag = "id name description path";
const User = "id givenName familyName avatar handle";
const User_ActionLog = "id userId action createdAt";

// const Style = tag;
// const Location = tag;
// const Occasion = tag;
// const Season = tag;
// const ProductStyle = tag;
// const Designer = tag;
// const HiddenTag = tag;

//    productStyles { productStyle { ${ProductStyle} } }
// designers { designer { ${Designer} } }
const Product = `
    id
    lookId
    name
    description
    price
    salePrice
    currency
    image
    images
    originalImages
    url
    productReferenceId
    catalogueProductReference {
        id
        productId
        region
    }
    retailer
    <auth>
    saved(where: {userId: {_eq: "#userId"}}) { userId }
    </auth>
`;

const Product_Stock = `
    id
    productId
    region
    price
    salePrice
    currency
    sizes
    sizeSystem
    availability
`;

// price
//     salePrice
//     designerIds
//     currency
const Catalogue_Product = `
    id
    catalogueId
    productWebId
    catalogueSource
    name
    description
    retailer
    brand
    url
    images
    details
    region
    price
    salePrice
    currency
    designerIds
`;

const Catalogue_Product_Stock = `
    id
    catalogueProductId
    region
    price
    salePrice
    currency
    sizes
    sizeSystem
    availability
`;

const ProductDraft = `
    id
    data
    createdAt
`;

const Look = `
    id
    consultationId
    title
    description
    path
    createdAt
    updatedAt
    layouts
    productMode
    fri { ${User} }
    products(order_by: {position: asc}) { ${Product} }
    <auth>
    saved(where: {userId: {_eq: "#userId"}}) { userId }
    </auth>
`;
// styles { style { ${Style} } }
//     seasons { season { ${Season} } }
//     locations { location { ${Location} } }
//     occasions { occasion { ${Occasion} } }

const LookList = `
    id
    name
`;

const LookListOrdering = `
    id
    listId
    lookId
    position
`;

const LookDraft = `
    id
    data
    mode
    createdAt
`;

const LookAdminChat = `
    id
    lookId
    friId
    adminId
    fri { ${User} }
    admin { ${User} }
    createdAt
    type
    message
`;

const Look_Style = "lookId styleId ordering";
const Look_Season = "lookId seasonId ordering";
const Look_Occasion = "lookId occasionId ordering";
const Look_Location = "lookId locationId ordering";
const Look_HiddenTag = "lookId hiddenTagId ordering";
const Product_Designer = "productId designerId";
const Product_ProductStyle = "productId productStyleId";

const UserProduct = `
    id
    product {
        id
        description
        image
    }
`;

const User_Contact = `
    id
    userId
    type
    value
    normalized
    notifications
    createdAt
`;

const User_Size = `
    id
    userId
    type
    system
    value
    comment
    createdAt
`;

const User_PaymentDetails = `
    id 
    userId
    paymentDetails
    billingAddress
    shippingAddress
`;

const User_SavedCatalogueProduct = `
    id
    productCatalogueId
`;

const User_CatalogueProduct_Reaction = `
    id
    catalogueProductId
    userId
    reaction
    createdAt
`;

const User_Reaction = `
    id
    userId
    lookProductId
    moodboardId
    lookId
    productReferenceId
    reaction
    createdAt
    catalogueProductReference {
        region
        productId
        images
    }
`;

const User_PublicInfo = `
    id
    givenName
    familyName
    avatar
`;

const Consultation = `
    id
    indexNo
    title
    contacts
    description
    due
    sizes
    status
    subStatus
    hidden
    createdAt
    updatedAt
    lastUpdateAt
    customerId
    customer { 
        ${User} 
        contacts {
            type
            value
            normalized
        }
    }
    friId
    fri { ${User} bio }
    images(order_by: { position: asc_nulls_first }) {
        id
        comment
        image
        position
    }
    products {
        id
        sizes
        product { ${Product} }
    }
    paymentDetailsId
    paymentDetails {
        paymentDetails
        billingAddress
        shippingAddress
    }

    looks {
        id
        updatedAt
    }

    looksCount: looks_aggregate(where: { deleted: { _eq: false }}) {
        aggregate {
          count
        }
    }
    publishedLooksCount: looks_aggregate(where: {published: {_eq: true}, 
                                                deleted: { _eq: false }}) {
        aggregate {
          count
        }
    }
`;

const Consultation_Contact = `
    id
    consultationId
    contactId
    contact {
        ${User_Contact}
    }
`;

const Consultation_Size = `
    id
    consultationId
    sizeId
    size {
        ${User_Size}
    }
`;

const Consultation_Customer_Reaction = `
    id
    productId
    userId
    consultationId
    reaction
    reactedAt
`;

const chatMessage = `
    id 
    message
    userId
    chatId
    consultationId
    lookId
    productId
    moodboardId
    action
    createdAt
    hidden
    uploading
    user {
        id
        givenName
        familyName
        avatar
        handle
    }
    notifications {
        read
    }
    voiceMessages {
        id
        s3key
        length
        uploading
    }
    images {
        id
        image
        uploading
    }
    reactions {
        id
        userId
        reaction
    }
    messageProductReferences {
        catalogueProductReference {
            region
            productId
            images
        }
    }
`;
// catalogueProducts {
//     id
//     catalogueProductId
// }

const chatMessageReply = `
    replySource {
        ${chatMessage}
    }
`;

const Chat_Message = `
    ${chatMessage}
    ${chatMessageReply}
`;

const Chat = `
    id
    consultationId
    lookId
    createdAt
    participants {
        userId
        role
    }
`;

const Chat_Message_Notification = `
    id 
    message { ${chatMessage} }
`;

const Chat_Message_Reaction = `
    id
    chatId
    messageId
    userId
    reaction
`;

const Chat_Message_ProductReference = `
    id
    productReferenceId
`;

// const Catalogue_Characteristic = `
//     id
//     name
//     synonyms
//     feedCharacteristics_aggregate {
//         aggregate {
//             count
//         }
//     }
// `;

// const Catalogue_Material = `
//     id
//     name
//     synonyms
//     feedMaterials_aggregate {
//         aggregate {
//             count
//         }
//     }
// `;

// const Catalogue_Color = `
//     id
//     name
//     synonyms
//     feedColors_aggregate {
//         aggregate {
//             count
//         }
//     }
// `;

// const Catalogue_Category = `
//     id
//     parentId
//     name
//     synonyms
//     position
//     treePosition
//     level
//     path
//     url
//     hidden
//     headerMenu
//     feedCategories_aggregate {
//         aggregate {
//             count
//         }
//     }
// `;

// const Catalogue_Designer = `
//     id
//     name
// `;

// const Catalogue_Designer_Top = `
//     id
//     position
//     designer {
//         id
//         name
//     }
// `;

const User_Notification_Combined = `
    id
    createdAt
    chatMessageNotificationId
    userNotificationId
    chatMessageNotification {
        id
        read
        message {
            id
            userId
            chatId
            action
            message
            look {
                id
                consultationId
            }
            product {
                id
                name
                look {
                    id
                    consultationId
                }
            }
            chat {
                id
                participants {
                    id
                    userId
                    role
                }
            }
            user {
                id
                givenName
                familyName
                avatar
            }
            messageProductReferences {
                catalogueProductReference {
                    productId
                    region
                    images
                }
            }
        }
    }
    userNotification {
        id
        type
        consultationId
        messageId
        fromUserId
        details
        read
        fromUser {
            id
            givenName
            familyName
            avatar
        }
        consultation {
            id
            customer {
                id
                givenName
                familyName
                avatar
            }
            fri {
                id
                givenName
                familyName
                avatar
                handle
            }
        }
    }
`;

const User_Notification = `
    id
    createdAt
    userId
    type
    consultationId
    details
    read
    readAt
`;

const User_OnlineState = `
    userId
    connectionUserId
    lastOnlineAt
`;

// const ExchangeRate = `
//     from
//     to
//     rate
// `;

// const Invitation = `
//     id
//     accepted
//     disabled
// `;

const Connection = `
    id
`;

const Retailer = `
    id
    name
    visible
`;

const Retailer_Commission = `
    retailerId
    affialiateId
    pseCommission
    tfCommission
    region
`;

const Banner = `
    id
    active
    endDate
    startDate
    text
`;

const User_Banner = `
    active
    bannerId
    userId
    countClosed
    id
`;

const Catalogue_ProductReference = `
    id
    productId
    region
    images
`;

const entities = {
    User: { list: User, update: true, subscribe: true },
    User_PublicInfo: { list: User_PublicInfo },
    User_ActionLog: { list: User_ActionLog, create: true, update: true },
    // User_SavedCatalogueProduct: {
    //     list: User_SavedCatalogueProduct,
    //     create: true,
    //     remove: true,
    // },
    User_Contact: {
        list: User_Contact,
        create: true,
        update: true,
        remove: true,
    },
    User_Size: { list: User_Size, create: true, update: true, remove: true },
    // User_SavedProduct: { create: true, remove: true },
    // User_SavedLook: { create: true, remove: true },
    User_PaymentDetails: {
        list: User_PaymentDetails,
        create: true,
        update: true,
        remove: true,
    },
    User_CatalogueProduct_Reaction: {
        list: User_CatalogueProduct_Reaction,
        create: true,
        update: true,
        remove: true,
    },
    User_Reaction: {
        list: User_Reaction,
        create: true,
        update: true,
        remove: true,
    },
    User_OnlineState: { list: User_OnlineState, create: true, update: true },

    Connection: { list: Connection, remove: true, update: true },

    Banner: { list: Banner, remove: true, update: true, create: true },
    User_Banner: { list: User_Banner, update: true, create: true },

    User_Notification_Combined: {
        list: User_Notification_Combined,
        subscribe: true,
    },
    User_Notification: { list: User_Notification, update: true },

    // Invitation: { list: Invitation },

    // UserProduct: {
    //     list: UserProduct,
    //     create: true,
    //     update: true,
    //     remove: true,
    // },
    // NewsletterRequest: { create: true },

    Look: { list: Look },
    LookList: { list: LookList },
    LookDraft: { list: LookDraft, create: true, update: true, remove: true },
    // LookListOrdering: { list: LookListOrdering },

    LogLookView: { create: "affected_rows" },
    LogProductPurchase: { create: "affected_rows" },
    LogLookShare: { create: "affected_rows" },

    Product: { list: Product },
    // Product_Stock: { list: Product_Stock },
    // ProductDraft: {
    //     list: ProductDraft,
    //     create: true,
    //     update: true,
    //     remove: true,
    // },

    Consultation: {
        list: Consultation,
        create: true,
        update: true,
        subscribe: true,
        remove: true,
    },
    Consultation_Contact: {
        list: Consultation_Contact,
        create: true,
        update: true,
        remove: true,
    },
    Consultation_Size: {
        list: Consultation_Size,
        create: true,
        update: true,
        remove: true,
    },
    Consultation_Customer_Reaction: {
        list: Consultation_Customer_Reaction,
        create: true,
        update: true,
        remove: true,
    },
    Consultation_Image: { create: true, update: true, remove: true },

    Chat: { list: Chat, create: true, update: true, remove: true },
    Chat_Participant: { list: true, create: true, update: true },
    Chat_Message: {
        list: Chat_Message,
        create: true,
        remove: true,
        update: true,
        subscribe: true,
    },
    Chat_Image: { create: true, remove: true, update: true },
    Chat_VoiceMessage: { create: true },
    // Chat_CatalogueProduct: { create: true },
    Chat_Message_Notification: {
        list: Chat_Message_Notification,
        subscribe: true,
        update: true,
    },
    Chat_Message_Reaction: {
        list: Chat_Message_Reaction,
        create: true,
        update: true,
        remove: true,
    },
    Chat_Message_ProductReference: {
        list: Chat_Message_ProductReference,
        create: true,
        remove: true,
    },
    // LookAdminChat: { list: LookAdminChat, create: true, update: true },

    // Style: { list: Style, create: true, update: true, remove: true },
    // Designer: { list: Designer, create: true, update: true, remove: true },
    // Season: { list: Season, create: true, update: true, remove: true },
    // Location: { list: Location, create: true, update: true, remove: true },
    // Occasion: { list: Occasion, create: true, update: true, remove: true },
    // ProductStyle: {
    //     list: ProductStyle,
    //     create: true,
    //     update: true,
    //     remove: true,
    // },
    // HiddenTag: { list: HiddenTag, create: true, update: true, remove: true },

    // Look_Style: { list: Look_Style, create: true, remove: true },
    // Look_Season: { list: Look_Season, create: true, remove: true },
    // Look_Location: { list: Look_Location, create: true, remove: true },
    // Look_Occasion: { list: Look_Occasion, create: true, remove: true },
    // Look_HiddenTag: { list: Look_HiddenTag, create: true, remove: true },
    // Product_ProductStyle: {
    //     list: Product_ProductStyle,
    //     create: true,
    //     remove: true,
    // },
    // Product_Designer: { list: Product_Designer, create: true, remove: true },

    // SearchHistory: { create: "affected_rows" },

    // Catalogue_Designer: { list: Catalogue_Designer },
    // Catalogue_Designer_Top: {
    //     list: Catalogue_Designer_Top,
    //     create: true,
    //     update: true,
    //     remove: true,
    // },
    // Catalogue_Category: { list: Catalogue_Category },
    // Catalogue_Color: { list: Catalogue_Color },
    // Catalogue_Material: { list: Catalogue_Material },
    // Catalogue_Characteristic: { list: Catalogue_Characteristic },

    // ExchangeRate: { list: ExchangeRate },

    // Catalogue_Product: { list: Catalogue_Product, create: true },
    // Catalogue_Product_Stock: { list: Catalogue_Product_Stock },
    Catalogue_ProductReference: {
        list: Catalogue_ProductReference,
        create: true,
    },

    App_Analytics_Timing: { create: true },
    User_Analytics: { update: true },

    Retailer: { list: Retailer },
    Retailer_Commission: { list: Retailer_Commission },
};

export default entities;
