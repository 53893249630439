import { Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "common/src/api";
import hub from "common/src/hub";
import { ui } from "common/src/store/dialogs";
import { useDispatch } from "react-redux";

import Table from "common/src/components/table/Table";
import UserSelectorDialog from "../../dialog/UserSelectorDialog";
import CJItemDetails from "./CJItemDetails";
import * as awin from "./network/awin";
import * as cj from "./network/cj";
import * as partnerize from "./network/partnerize";
import * as rakuten from "./network/rakuten";
import PartnerizeItemDetails from "./PartnerizeItemDetails";
import RakutenItemDetails from "./RakutenItemDetails";

const networkDetails = {
    partnerize,
    rakuten,
    cj,
    awin,
};

function CommissionDetails(
    { commission, onShowClickInfo, onShowCommissionHistory, getFilterLink },
) {
    const [ products, setProducts ] = useState(null);
    const [ showRaw, setShowRaw ] = useState(false);
    const dispatch = useDispatch();

    const renderRakutenItemDetails = useCallback(
        (order, item) => {
            return <RakutenItemDetails order={order} item={item} />;
        },
        [],
    );

    const renderPartnerizeItemDetails = useCallback(
        (order, item) => {
            return <PartnerizeItemDetails order={order} item={item} />;
        },
        [],
    );

    const renderCJItemDetails = useCallback(
        (order, item) => {
            return <CJItemDetails order={order} item={item} />;
        },
        [],
    );

    const getProducts = useCallback(
        async () => {
            const comm = commission;
            const networkId = comm.networkId || comm.network?.id;
            const products = await networkDetails[networkId]
                ?.getProducts(
                    comm,
                    comm.networkId === "rakuten"
                        ? renderRakutenItemDetails
                        : comm.networkId === "partnerize"
                        ? renderPartnerizeItemDetails
                        : comm.networkId === "cj"
                        ? renderCJItemDetails
                        : null,
                )
                || null;

            setProducts(products);
        },
        [ commission, renderRakutenItemDetails, renderPartnerizeItemDetails ],
    );

    const onAssignPSEClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(ui.show("user-selector"));
        },
        [ dispatch ],
    );

    const onUserSelect = useCallback(
        async (user) => {
            console.log("User selected", user);
            await api.backend.post("/order/assign-pse", {
                body: {
                    orderId: commission.orderId,
                    userId: user.id,
                },
            });
            hub.dispatch("app", "order-item-status-update");
        },
        [ commission.id ],
    );

    useEffect(
        () => {
            getProducts();
        },
        [ getProducts ],
    );

    const renderToolbar = useCallback(
        () => {
            const network = networkDetails[commission.networkId];
            const hasHistory = network.hasHistory(commission);
            const hasCorrections = network.hasCorrections(commission);

            return (
                <div className="page-commission-details-toolbar">
                    {!!commission.clickId
                        && (
                            <Button
                                variant="outlined"
                                size="small"
                                children="Show click info"
                                onClick={() => onShowClickInfo(commission)} />
                        )}
                    {hasHistory
                        && (
                            <Button
                                variant="outlined"
                                size="small"
                                children="Show history"
                                onClick={() =>
                                    onShowCommissionHistory(commission)} />
                        )}
                    {hasCorrections
                        && (
                            <Button
                                variant="outlined"
                                size="small"
                                children="Show corrections"
                                onClick={() =>
                                    onShowCommissionHistory(commission)} />
                        )}
                    {commission.details
                        && (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => setShowRaw(prev => !prev)}
                                children={showRaw
                                    ? "Hide raw details"
                                    : "Show raw details"} />
                        )}

                    {/*<span className="spacer"/>*/}

                    {(getFilterLink && !!commission.clickId)
                        && (
                            <Link
                                children="Filter by this click"
                                to={getFilterLink(
                                    "click",
                                    commission.clickId,
                                )} />
                        )}
                    {(getFilterLink && !!commission.networkId)
                        && (
                            <Link
                                children="Filter by this network"
                                to={getFilterLink(
                                    "network",
                                    commission.networkId,
                                )} />
                        )}
                    {(getFilterLink && !!commission.advertiser)
                        && (
                            <Link
                                children="Filter by this advertiser"
                                to={getFilterLink(
                                    "advertiser",
                                    commission.advertiser,
                                )} />
                        )}
                    {(getFilterLink && !!commission.clickId
                        && commission.click.product)
                        && (
                            <Link
                                children="Filter by this FRI"
                                to={getFilterLink(
                                    "fri",
                                    commission.click.product.look.fri.id,
                                )} />
                        )}
                    {(getFilterLink && !!commission.clickId
                        && commission.click.referenceUser)
                        && (
                            <Link
                                children="Filter by this Contributor"
                                to={getFilterLink(
                                    "contributor",
                                    commission.click.referenceUser.id,
                                )} />
                        )}
                    {(getFilterLink && !!commission.clickId)
                        && (
                            <Link
                                children="Show click"
                                to={getFilterLink(
                                    "click-log",
                                    commission.clickId,
                                )} />
                        )}
                    {(commission.click && !commission.click.shopperId
                        && !commission.click.referenceUserId) && (
                        <a href="/#" onClick={onAssignPSEClick}>Assign PSE</a>
                    )}
                </div>
            );
        },
        [
            commission,
            onShowClickInfo,
            onShowCommissionHistory,
            getFilterLink,
            showRaw,
        ],
    );

    const renderRawDetails = useCallback(
        () => {
            const code = JSON.stringify(
                JSON.parse(commission.details),
                null,
                2,
            );

            return (
                <div className="page-commission-details-raw">
                    <pre>
                        { code }
                    </pre>
                </div>
            );
        },
        [ commission ],
    );

    return (
        <div className="page-commission-details">
            {products
                && (
                    <Table
                        className="page-commission-details-products"
                        {...products} />
                )}

            {renderToolbar()}
            {showRaw && renderRawDetails()}
            <UserSelectorDialog onSelect={onUserSelect} />
        </div>
    );
}

export default CommissionDetails;

/*
{ code &&
                    <div className="page-commission-details-code">
                        { code }
                    </div> }*/
