import { MenuItem, Select } from "@mui/material";
import { setCJItemOrderStatus } from "app/actions/page/commissions";
import React, { useCallback, useState } from "react";

export default function CJItemDetails({ order, item }) {
    const [ manualStatus, setManualStatus ] = useState(item.manualStatus);

    const onChangeManualStatus = useCallback(
        ({ target: { value } }) => {
            if (value === "unset") {
                value = null;
            }
            setManualStatus(value);
            setCJItemOrderStatus(item.id, value);
        },
        [ item ],
    );

    return (
        <div className="page-commission-details-toolbar">
            <p>Manual status:</p>&nbsp;
            <Select
                size="small"
                value={manualStatus || "unset"}
                onChange={onChangeManualStatus}>
                <MenuItem value="unset">Unset</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
            </Select>
        </div>
    );
}
