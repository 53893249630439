import { MenuItem, Select } from "@mui/material";
import { setRakutenItemOrderStatus } from "app/actions/page/commissions";
import { setPartnerizeItemOrderStatus } from "app/actions/page/commissions";
import { setCJItemOrderStatus } from "app/actions/page/commissions";
import React, { useCallback, useState } from "react";

function CommissionProductDetails({ row }) {
    const [ manualStatus, setManualStatus ] = useState(row.manualStatus);

    const onChangeManualStatus = useCallback(
        ({ target: { value } }) => {
            if (value === "unset") {
                value = null;
            }
            // console.log(
            //     "onChangeManualStatus",
            //     value,
            //     row.order.networkId,
            //     row.id,
            // );
            setManualStatus(value);
            if (row.order.networkId === "rakuten") {
                setRakutenItemOrderStatus(row.id, value);
            }
            else if (row.order.networkId === "partnerize") {
                setPartnerizeItemOrderStatus(row.id, value);
            }
            else if (row.order.networkId === "cj") {
                setCJItemOrderStatus(row.id, value);
            }
        },
        [ row ],
    );

    return (
        <div className="page-commission-details">
            <div className="page-commission-details-toolbar">
                <p>Manual status:</p>&nbsp;
                <Select
                    size="small"
                    value={manualStatus || "unset"}
                    onChange={onChangeManualStatus}>
                    <MenuItem value="unset">Unset</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                    <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
            </div>
        </div>
    );
}

export default CommissionProductDetails;
