
import React from "react"
import Select from "@mui/material/Select"
import FormControl from '@mui/material/FormControl'
import InputLabel from "@mui/material/InputLabel"
import FormHelperText from "@mui/material/FormHelperText"


class FormSelect extends React.Component {

    render() {
        let { label, helperText, ...rest } = this.props;
        return (
            <FormControl className="MuiSelectField-root">
                { label && <InputLabel>{ label }</InputLabel> }
                <Select { ...rest }/>
                { helperText && <FormHelperText>{ helperText }</FormHelperText> }
            </FormControl>
        )
    }
}

export default FormSelect