import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import { Button, FormControlLabel, MenuItem } from "@mui/material";
import AccessTree from "app/components/AccessTree";
import SideDialog from "common/src/components/dialog/SideDialog";
import Checkbox from "common/src/components/material/Checkbox";
import Select from "common/src/components/material/Select";
import UserBlock from "common/src/components/user/User";

import { defaultAccess, defaultRetailers } from "app/accessTree";
import {
    deleteUser,
    setDefaultShopper,
    setEnabled,
    setGroup,
} from "app/actions/page/people";
import api from "app/api";
import { alert, confirm, prompt } from "common/src/components/dialog/Alert";
import Loader from "common/src/components/Loader";
import hub from "common/src/hub";
import df from "common/src/lib/date/formats";
import s3url from "common/src/lib/image/s3url";
import async from "common/src/lib/js/async";
import { ui as ddUi } from "common/src/store/dialogs";
import { default as currentUser } from "common/src/user";
import { connect } from "react-redux";

const userIsOnly = (group, groups) => {
    if (typeof groups === "string") {
        groups = groups.split(",");
    }
    if (groups.indexOf(group) === -1) {
        return false;
    }
    if (group === "User") {
        return groups.length === 1;
    }
    return groups.length === 2;
};

const dashboardTypes = [
    {
        label: "Commissions",
        value: "commissions",
    },
    {
        label: "Sales",
        value: "sales",
    },
];

const UserDetails = ({ user, dd, dispatch }) => {
    const [ state, setState ] = useState({
        id: null,
        changingAccess: null,
        changingRetailer: null,
        changingUserGroup: null,
        changingUserRole: null,
        defaultShopper: false,
        groups: [],
        retailers: [],
        savedUserGroups: [],
        access: {},
        enabled: false,
        enabling: false,
        deleting: false,
        settingPassword: false,
        settingDefaultShopper: false,
        dashboardType: null,
    });

    const [ preventUpdateTrigger, setPreventUpdateTrigger ] = useState(false);

    useEffect(() => {
        if (!user) return;

        async(() => {
            setState(prev => ({
                ...prev,
                id: user.id,
                groups: (user.groups || "").split(","),
                enabled: user.enabled,
                access: { ...user.details.access },
                retailers: [ ...(user.details.retailers || []) ],
                savedUserGroups: user.userGroups?.map((ug) => ug.group) || [],
                enabling: false,
                deleting: false,
                defaultShopper: user.defaultShopper,
                changingGroup: [],
                changingRetailer: null,
                changingAccess: null,
                dashboardType: user.details?.dashboardType || "commissions",
            }));
        });
    }, [ user ]);

    const onDialogClose = useCallback(() => {
        dispatch(ddUi.hide("user-details"));
        async(() => setState(prev => ({ ...prev, id: null })));
    }, [ dispatch ]);

    const setAccessKey = async (key, value, userDetails = null) => {
        setState(prev => ({ ...prev, changingAccess: key }));

        const newAccess = { ...state.access };
        if (value === true) {
            newAccess[key] = value;
        }
        else {
            delete newAccess[key];
        }

        const details = userDetails
            ? { ...userDetails }
            : { ...user.details };
        details.access = newAccess;

        await api.user.update(user.id, {
            details: JSON.stringify(details),
        });

        if (!preventUpdateTrigger) {
            hub.dispatch("users", "updated", user.id);
        }

        setState(prev => ({
            ...prev,
            access: newAccess,
            changingAccess: null,
        }));

        return details;
    };

    const saveDashboardType = useCallback(
        async (type) => {
            setState(prev => ({ ...prev, dashboardType: type }));
            await api.user.update(user.id, {
                details: JSON.stringify({
                    ...user.details,
                    dashboardType: type,
                }),
            });
            if (!preventUpdateTrigger) {
                hub.dispatch("users", "updated", user.id);
            }
        },
        [ user, preventUpdateTrigger ],
    );

    const setRetailerAccess = async (retailer, value, userDetails = null) => {
        setState(prev => ({ ...prev, changingRetailer: retailer }));

        const retailers = [ ...state.retailers ];
        const inx = retailers.indexOf(retailer);

        if (value === true && inx === -1) {
            retailers.push(retailer);
        }
        else if (value === false && inx !== -1) {
            retailers.splice(inx, 1);
        }

        const details = userDetails
            ? { ...userDetails }
            : { ...user.details };
        details.retailers = retailers;

        await api.user.update(user.id, {
            details: JSON.stringify(details),
        });

        if (!preventUpdateTrigger) {
            hub.dispatch("users", "updated", user.id);
        }

        setState(prev => ({
            ...prev,
            retailers,
            changingRetailer: null,
        }));

        return details;
    };

    const grpTagOnClick = async (g) => {
        const grps = [ ...state.groups ];
        const inx = grps.indexOf(g.name);
        let addAccess, addRetailers;

        if (inx !== -1) {
            grps.splice(inx, 1);
        }
        else {
            grps.push(g.name);
            addAccess = defaultAccess[g.name];
            addRetailers = defaultRetailers[g.name];
        }

        setState(prev => ({ ...prev, changingUserRole: g.name }));

        let userDetails = { ...user.details };

        try {
            await setGroup(user.id, grps.join(","));
            setState(prev => ({ ...prev, groups: grps }));
            setPreventUpdateTrigger(true);

            if (addAccess) {
                for (const access of addAccess) {
                    userDetails = await setAccessKey(access, true, userDetails);
                }
            }
            if (addRetailers) {
                for (const retailer of addRetailers) {
                    userDetails = await setRetailerAccess(
                        retailer,
                        true,
                        userDetails,
                    );
                }
            }

            setPreventUpdateTrigger(false);
            hub.dispatch("users", "updated", user.id);
        }
        finally {
            setState(prev => ({ ...prev, changingUserRole: null }));
        }
    };

    const handleSetDefaultShopper = async (defaultShopper) => {
        setState(prev => ({ ...prev, settingDefaultShopper: true }));
        await setDefaultShopper(user.id, defaultShopper);
        setState(prev => ({
            ...prev,
            defaultShopper,
            settingDefaultShopper: false,
        }));
    };

    const toggleEnabled = async () => {
        setState(prev => ({ ...prev, enabling: true }));

        try {
            await setEnabled(user.id, !state.enabled);
            setState(prev => ({ ...prev, enabled: !prev.enabled }));
        }
        finally {
            setState(prev => ({ ...prev, enabling: false }));
        }
    };

    const handleDeleteUser = async () => {
        try {
            await confirm({
                title: "Are you sure?",
                message: "This cannot be undone.",
            });

            setState(prev => ({ ...prev, deleting: true }));
            await deleteUser(user.id);

            await alert({
                title: "Success",
                message: "User has been deleted",
            });

            onDialogClose();
        }
        catch {
            // User cancelled the operation
        }
        finally {
            setState(prev => ({ ...prev, deleting: false }));
        }
    };

    const handleSetPassword = async () => {
        try {
            const pres = await prompt({ message: "Enter new password" });
            const password = (pres.prompt || "").trim();

            if (password) {
                setState(prev => ({ ...prev, settingPassword: true }));

                const res = await api.backend.post("/user/set-password", {
                    body: {
                        userId: user.id,
                        password,
                    },
                });

                if (res.success) {
                    alert({ message: "User's password updated" });
                }
                else {
                    alert({
                        title: "Failed to update user's password",
                        message: res.error?.message || "",
                    });
                }

                setState(prev => ({ ...prev, settingPassword: false }));
            }
        }
        catch {
            // User cancelled the operation
        }
    };

    const removeOldPermissions = async (keys) => {
        setState(prev => ({ ...prev, changingAccess: "old-permissions" }));

        const newAccess = { ...state.access };
        keys.forEach((key) => delete newAccess[key]);

        const details = { ...user.details };
        details.access = newAccess;

        await api.user.update(user.id, {
            details: JSON.stringify(details),
        });

        if (!preventUpdateTrigger) {
            hub.dispatch("users", "updated", user.id);
        }

        setState(prev => ({
            ...prev,
            access: newAccess,
            changingAccess: null,
        }));

        return details;
    };

    const show = user && dd["user-details"];
    const avatarUrl = user ? s3url(user.avatar) : null;
    const groupTags = [
        { name: "User" },
        { name: "FRI", skip: true },
        { name: "GPS", label: "PSE" },
        { name: "Admin" },
        { name: "Contributor", skip: true },
    ];

    if (!user) return null;

    return (
        <SideDialog
            className="dialog-user"
            title="Person details"
            onClose={onDialogClose}
            show={show}>
            <div className="user-card">
                {avatarUrl && (
                    <div
                        className="image"
                        style={{
                            backgroundImage: `url(${avatarUrl})`,
                        }} />
                )}

                <UserBlock
                    variant="reverse"
                    user={user}
                    after={
                        <>
                            <br />
                            <span className="email">{user.email}</span>
                        </>
                    } />

                <h4>Roles</h4>
                <div className="user-details-roles">
                    {groupTags.map((g) => {
                        if (g.skip && state.groups.indexOf(g.name) === -1) {
                            return null;
                        }
                        return (
                            <FormControlLabel
                                key={g.name}
                                className="user-details-retailer"
                                control={state.changingUserRole === g.name
                                    ? <Loader inline />
                                    : (
                                        <Checkbox
                                            color="primary"
                                            disabled={!!state.changingUserRole}
                                            checked={state.groups.indexOf(
                                                g.name,
                                            ) !== -1}
                                            onChange={() => grpTagOnClick(g)} />
                                    )}
                                label={g.label || g.name} />
                        );
                    })}
                </div>

                {currentUser.is([ "Admin" ])
                    && !userIsOnly("Contributor", state.groups)
                    && !userIsOnly("User", state.groups) && (
                    <FormControlLabel
                        className="default-shopper"
                        control={state.settingDefaultShopper
                            ? <Loader inline />
                            : (
                                <Checkbox
                                    color="primary"
                                    checked={state.defaultShopper}
                                    onChange={(e) =>
                                        handleSetDefaultShopper(
                                            e.target.checked,
                                        )} />
                            )}
                        label="Default Shopper" />
                )}

                <h4>Partial access</h4>
                <AccessTree
                    filter={userIsOnly("Contributor", state.groups)
                        ? [ "web", "tools/catalogue" ]
                        : null}
                    access={state.access}
                    disabled={!!state.changingAccess}
                    loading={state.changingAccess}
                    onChange={(key, value) => setAccessKey(key, value)}
                    onRemoveOldPermissions={(keys) =>
                        removeOldPermissions(keys)} />

                {state.groups.indexOf("GPS") !== -1 && (
                    <>
                        <h4>PSE Dashboard type</h4>
                        <Select
                            size="small"
                            variant="standard"
                            value={state.dashboardType}
                            onChange={(e) => saveDashboardType(e.target.value)}>
                            {dashboardTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}

                <p className="phone">{user.phone}</p>
                <p className="created">
                    Signed up: {moment(user.createdAt).format(df.date)}
                    <br />
                    ID: {user.id}
                </p>

                <div className="actions">
                    <Button
                        variant={state.enabled ? "contained" : "outlined"}
                        startIcon={state.enabling ? <Loader inline /> : null}
                        disabled={state.enabling}
                        onClick={toggleEnabled}>
                        {state.enabled ? "Set disabled" : "Set enabled"}
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={state.deleting ? <Loader inline /> : null}
                        disabled={state.enabled || state.deleting}
                        onClick={handleDeleteUser}>
                        Delete user
                    </Button>
                </div>

                {state.enabled && (
                    <div className="actions">
                        <Button
                            variant="contained"
                            startIcon={state.settingPassword
                                ? <Loader inline />
                                : null}
                            disabled={state.settingPassword}
                            onClick={handleSetPassword}>
                            Set password
                        </Button>
                    </div>
                )}
            </div>
        </SideDialog>
    );
};

export default connect((state) => ({
    dd: state.dialogs,
    userGroups: state.peoplePage.data.userGroups,
}))(UserDetails);
